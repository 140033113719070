import React, { useState, useEffect } from "react";
import { usePeticion } from "../../herramientas";
import { useNavigate, Link, useParams, Form } from "react-router-dom";

export default function AgregarDocumento() {
  let { id_apoderado } = useParams();
  const navigate = useNavigate();
  const peticion = usePeticion();
  const [estado, setEstado] = useState("cargado");

  function handleSubmit(event) {
    event.preventDefault();
    setEstado("cargando");
    let formData = new FormData(event.currentTarget);
    formData.append("id_apoderado", id_apoderado);
    peticion(
      "apoderados/documentos/agregar/",
      {
        method: "POST",
        body: formData,
      },
      "Agregar documentos de apoderado",
      "No han podido ser añadidos los documentos satisfactoriamente",
      () => {
        navigate("../../perfil/" + id_apoderado);
      }
    );
  }

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Agregar documentos</h1>
        <form onSubmit={handleSubmit} class="row g-3">
          <div className="col-md-12">
            <label htmlFor="documentos" class="form-label">
              Documentos
            </label>
            <input type="file" multiple className="form-control" id="documentos" name="documentos" />
            <div className="form-text">Puede seleccionar varios archivos.</div>
          </div>
          <div className="col-md-12">
            <button type="submit" href="perfil.html" class="btn btn-success me-3">
              <i className="bi bi-upload"></i> Subir documentos
            </button>
            <Link to={"../../perfil/" + id_apoderado} role="button" href="perfil.html" className="btn btn-danger">
              <i className="bi bi-x-lg"></i> Cancelar
            </Link>
          </div>
        </form>
      </>
    );
  }
}
