import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link, useParams, Form } from "react-router-dom";

export default function ModificarDatos() {
  let { id_alumno } = useParams();
  const peticion = usePeticion();
  const navigate = useNavigate();

  const [estado, setEstado] = useState("cargando");
  const [aulas, setAulas] = useState([]);
  const [datos, setDatos] = useState({});

  useEffect(function () {
    peticion("alumnos/perfil/" + id_alumno, {}, "Alumnos", "No ha podido ser cargados los datos del alumno", (json) => {
      setDatos(json.datos);
      setAulas(json.aulas);
      setEstado("cargado");
    });
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    formData.append("id_alumno", id_alumno);
    peticion(
      "alumnos/modificar/",
      {
        method: "PUT",
        body: formData,
      },
      "Modificar datos de alumno",
      "No ha sido modificado el alumno satisfatoriamente",
      () => {
        navigate("../perfil/" + id_alumno);
      }
    );
  }

  function handleEliminar() {
    if (window.confirm("Está seguro de aliminar al alumno: " + datos.nombre_completo)) {
      let formData = new FormData();
      formData.append("id_alumno", id_alumno);
      peticion(
        "alumnos/eliminar/",
        {
          method: "DELETE",
          body: formData,
        },
        "Eliminar alumno",
        "No ha sido eliminado el alumno satisfatoriamente",
        {
          titulo: "Eliminar alumno",
          mensaje: "Alumno eliminado satisfactoriamente",
          botones: [
            {
              direccion: "../alumnos",
              color: "primary",
              nombre: "Ir a Alumnos",
            },
          ],
        }
      );
    }
  }

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Modificar datos</h1>
        <p className="lead">{datos.nombre_completo}</p>
        <form onSubmit={handleSubmit} className="row g-3">
          <div className="col-md-9">
            <label htmlFor="nombre_completo" className="form-label">
              Nombre completo
            </label>
            <input type="text" required defaultValue={datos.nombre_completo} className="form-control" id="nombre_completo" name="nombre_completo" />
          </div>
          <div className="col-md-3">
            <label htmlFor="id_aula" className="form-label">
              Grado
            </label>
            <select id="id_aula" name="id_aula" defaultValue={datos.id_aula} className="form-select">
              <optgroup label="Inicial">
                {aulas.map((aula) => (aula.nivel == "Inicial" ? <option value={aula.id_aula} key={aula.id_aula}>{`${aula.nombre}`}</option> : null))}
              </optgroup>
              <optgroup label="Primaria">
                {aulas.map((aula) => (aula.nivel == "Primaria" ? <option value={aula.id_aula} key={aula.id_aula}>{`${aula.nombre}`}</option> : null))}
              </optgroup>
              <optgroup label="Secundaria">
                {aulas.map((aula) => (aula.nivel == "Secundaria" ? <option value={aula.id_aula} key={aula.id_aula}>{`${aula.nombre}`}</option> : null))}
              </optgroup>
            </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="correo" className="form-label">
              Email
            </label>
            <input type="email" className="form-control" defaultValue={datos.correo} name="correo" id="correo" />
          </div>
          <div className="col-md-3">
            <label htmlFor="dni" className="form-label">
              DNI
            </label>
            <input type="text" defaultValue={datos.dni} className="form-control" name="dni" id="dni" />
          </div>
          <div className="col-md-3">
            <label htmlFor="saldo" className="form-label">
              Saldo
            </label>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon1">
                S/
              </span>
              <input type="number" step={0.01} defaultValue={datos.saldo} className="form-control" name="saldo" id="saldo" />
            </div>
          </div>
          <div className="col-md-3">
            <label htmlFor="matricula2023" className="form-label">
              Matricula 2023
            </label>
            <select id="matricula2023" name="matricula2023" defaultValue={datos.matricula2023} className="form-select">
              <option value={1}>Sí</option>
              <option value={0}>No</option>
            </select>
          </div>
          <div className="col-md-12">
            <label htmlFor="direccion" className="form-label">
              Dirección
            </label>
            <textarea className="form-control" defaultValue={datos.direccion} name="direccion" id="direccion" rows="2" />
          </div>
          <div className="col-md-12">
            <label htmlFor="observaciones" className="form-label">
              Observaciones
            </label>
            <textarea className="form-control" defaultValue={datos.observaciones} name="observaciones" id="observaciones" rows="5" />
          </div>
          <div className="col-md-12">
            <button type="submit" className="btn btn-warning me-3">
              <i className="bi bi-pencil"></i> Guardar cambios
            </button>
            <button type="button" onClick={handleEliminar} className="btn btn-danger me-3">
              <i class="bi bi-trash3-fill"></i> Eliminar alumno
            </button>
            <Link to={"../perfil/" + id_alumno} role="button" href="perfil.html" className="btn btn-secondary">
              <i className="bi bi-x-lg"></i> Cancelar
            </Link>
          </div>
        </form>
      </>
    );
  }
}
