import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link, useParams, Form } from "react-router-dom";

export default function ModificarDatos() {
  let { id_apoderado } = useParams();
  const peticion = usePeticion();
  const navigate = useNavigate();

  const [estado, setEstado] = useState("cargando");
  const [aulas, setAulas] = useState([]);
  const [datos, setDatos] = useState({});

  const [dni, setDni] = useState("")
  const [nombreCompleto, setNombreCompleto] = useState("")

  useEffect(function () {
    peticion("apoderados/perfil/" + id_apoderado, {}, "Apoderados", "No ha podido ser cargados los datos del apoderado", (json) => {
      setDatos(json.datos);
      setNombreCompleto(json.datos.nombre_completo)
      setDni(json.datos.dni)
      setEstado("cargado");
    });
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    formData.append("id_apoderado", id_apoderado)
    peticion(
      "apoderados/modificar/",
      {
        method: "PUT",
        body: formData,
      },
      "Modificar datos de apoderado",
      "No ha sido modificado el apoderado satisfatoriamente",
      () => {
        navigate("../perfil/" + id_apoderado);
      }
    );
  }

  function rellenarNombreCompleto() {
    peticion(
      "apoderados/consultar_nombre_completo_por_dni/" + dni,
      {},
      "Consultar nombre por DNI",
      "No se ha podido consultar el nombre del cliente por su DNI.",
      (json) => {
        setNombreCompleto(json.denominacion);
      }
    );
  }

  function handleEliminar() {
    if (window.confirm("Está seguro de aliminar al apoderado: " + datos.nombre_completo)) {
      let formData = new FormData();
      formData.append("id_apoderado", id_apoderado);
      peticion(
        "apoderados/eliminar/",
        {
          method: "DELETE",
          body: formData,
        },
        "Eliminar apoderado",
        "El apoderado no ha podido ser eliminado satisfactoriamente",
        { titulo: "Eliminar apoderado", mensaje: "Apoderado eliminado satisfactoriamente", botones: [
          {
            direccion: "../apoderados",
            color: "primary",
            nombre: "Ir a Apoderados"
          }
        ] }
      );
    }
  }

  if (estado === "cargando") {
    return (
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Modificar datos</h1>
        <p class="lead">{datos.nombre_completo}</p>
        <form onSubmit={handleSubmit} class="row g-3">
          <div class="col-md-4">
            <label htmlFor="dni" class="form-label">
              DNI
            </label>
            <input type="text" onChange={(e) => setDni(e.target.value)} value={dni} class="form-control" name="dni" id="dni" />
          </div>
          <div class="col-md-8">
            <label htmlFor="nombre_completo" class="form-label">
              Nombre completo
            </label>
          <div className="input-group">
          <button className="btn btn-outline-primary" onClick={rellenarNombreCompleto} type="button">
                <i className="bi bi-arrow-right-square"></i> Rellenar
              </button>
            <input type="text" value={nombreCompleto} onChange={(e) => setNombreCompleto(e.target.value)} className="form-control" id="nombre_completo" name="nombre_completo" />
          </div>
          </div>
          <div class="col-md-12">
            <label htmlFor="correo" class="form-label">
              Email
            </label>
            <input type="email" class="form-control" defaultValue={datos.correo} name="correo" id="correo" />
          </div>
          <div class="col-md-12">
            <label htmlFor="observaciones" class="form-label">
              Observaciones
            </label>
            <textarea class="form-control" defaultValue={datos.observaciones} name="observaciones" id="observaciones" rows="3" />
          </div>
          <div class="col-md-12">
            <button type="submit" href="perfil.html" class="btn btn-warning me-3">
              <i class="bi bi-pencil"></i> Guardar cambios
            </button>
            <button type="button" onClick={handleEliminar} className="btn btn-danger me-3">
              <i class="bi bi-trash3-fill"></i> Eliminar apoderado
            </button>
            <Link to={"../perfil/"+id_apoderado} role="button" class="btn btn-secondary">
              <i class="bi bi-x-lg"></i> Cancelar
            </Link>
          </div>
        </form>
      </>
    );
  }
}
