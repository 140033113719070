import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link, useParams, Form } from "react-router-dom";

export default function Index() {
  let { id_cuota_pago } = useParams();
  const peticion = usePeticion();
  const navigate = useNavigate();

  const [estado, setEstado] = useState("cargando");
  const [metodosPago, setMetodosPagos] = useState([]);
  const [cuota, setCuota] = useState({});

  useEffect(function () {
    peticion("pagos/cuota/" + id_cuota_pago, {}, "Modificar cuota", "No han podido ser cargados los datos para la cuota.", (json) => {
      setMetodosPagos(json.metodos_pago);
      setCuota(json.cuota);
      setEstado("cargado");
    });
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    setEstado("cargando")
    let formData = new FormData(event.currentTarget);
    formData.append("id_cuota_pago", id_cuota_pago)
    peticion(
      "pagos/modificar_cuota/",
      {
        method: "PUT",
        body: formData,
      },
      "Modificar cuota",
      "La cuota no ha podido modificarse de forma satisfactoria.",
      (json) => {
        navigate("../cuotas/" + cuota.id_pago_alumno);
      }
    );
  }

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Modificar cuota</h1>
        <form onSubmit={handleSubmit} className="row g-3">
          <div className="col-md-6">
            <label htmlFor="monto" className="form-label">
              Monto
            </label>
            <input type="number" step={0.01} min={0.01} defaultValue={cuota.monto} required className="form-control" id="monto" name="monto" />
          </div>
          <div className="col-md-6">
            <label htmlFor="id_metodo_pago" className="form-label">
              Método de pago
            </label>
            <select id="id_metodo_pago" name="id_metodo_pago" defaultValue={cuota.id_metodo_pago} className="form-select">
              {metodosPago.map((metodo) => (
                <option value={metodo.id_metodo_pago}>{metodo.nombre}</option>
              ))}
            </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="fecha" className="form-label">
              Fecha
            </label>
            <input type="date" required className="form-control" defaultValue={cuota.fecha} id="fecha" name="fecha" />
          </div>
          <div className="col-md-6">
            <label htmlFor="imagen_comprobante" className="form-label">
              Reemplazar imagen de comprobante
            </label>
            <input type="file" accept="image/*" className="form-control" name="imagen_comprobante" id="imagen_comprobante" />
            <div className="form-text">Si no se sube ninguna imagen, se conservará la anterior.</div>
          </div>
          <div className="col-md-12">
            <label htmlFor="observaciones" className="form-label">
              Observaciones
            </label>
            <textarea className="form-control" defaultValue={cuota.observaciones} name="observaciones" id="observaciones" rows="5" />
          </div>
          <div className="col-md-12">
            <button type="submit" className="btn btn-warning me-3">
              <i className="bi bi-pencil"></i> Modificar cuota
            </button>
            <button type="button" onClick={() => navigate(-1)} className="btn btn-secondary">
              <i className="bi bi-x-lg"></i> Cancelar
            </button>
          </div>
        </form>
      </>
    );
  }
}
