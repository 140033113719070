import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link, useParams, Form } from "react-router-dom";

export default function Index() {
  let { id_pago_alumno } = useParams();
  const peticion = usePeticion();
  const navigate = useNavigate();

  const [estado, setEstado] = useState("cargando");
  const [pago, setPago] = useState({});
  const [estadosPago, setEstadosPago] = useState([])

  useEffect(function () {
    peticion("pagos/cuotas/" + id_pago_alumno, {}, "Modificar pago", "No han podido ser cargados los datos del pago.", (json) => {
      setPago(json.pago_alumno);
      setEstadosPago(json.estados_pago);
      setEstado("cargado");
    });
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    setEstado("cargando")
    let formData = new FormData(event.currentTarget);
    formData.append("id_pago_alumno", id_pago_alumno)
    peticion(
      "pagos/modificar_pago/",
      {
        method: "PUT",
        body: formData,
      },
      "Modificar estado pago",
      "El pago no ha podido modificarse de forma satisfactoria.",
      (json) => {
        navigate("../cuotas/" + id_pago_alumno);
      }
    );
  }

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Modificar estado del pago</h1>
        <form onSubmit={handleSubmit} className="row g-3">
          <div className="col-md-12">
            <label htmlFor="id_estado_pago" className="form-label">
              Estado del pago
            </label>
            <select id="id_estado_pago" name="id_estado_pago" defaultValue={pago.id_estado_pago} className="form-select">
              {estadosPago.map((estadoPago) => (
                <option value={estadoPago.id_estado_pago}>{estadoPago.estado}</option>
              ))}
            </select>
          </div>
          <div className="col-md-12">
            <button type="submit" className="btn btn-warning me-3">
              <i className="bi bi-pencil"></i> Modificar estado del pago
            </button>
            <button type="button" onClick={() => navigate(-1)} className="btn btn-secondary">
              <i className="bi bi-x-lg"></i> Cancelar
            </button>
          </div>
        </form>
      </>
    );
  }
}
