import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Exito, Error, NoAutorizado } from './confirmaciones';
import IniciarSesion from './iniciar_sesion/index'
import Alumnos from './alumnos/index'
  import AlumnosPerfil from './alumnos/perfil'
  import AlumnosModificarDatos from './alumnos/modificar_datos'
    import AlumnosAgregarDocumentos from './alumnos/documentos/agregar_documento'
    import AlumnosRenombrarDocumento from './alumnos/documentos/renombrar_documento'
  import AlumnosAsignarApoderado from './alumnos/asignar_apoderado'
  import AlumnosRegistrar from './alumnos/registrar';
  import AlumnosAsignarApoderadoNuevo from './alumnos/asignar_apoderado_nuevo';
  import AlumnosEditarParentesco from './alumnos/editar_parentesco';

import Apoderados from './apoderados/index'
  import ApoderadosPerfil from './apoderados/perfil'
  import ApoderadosModificarDatos from './apoderados/modificar_datos'
  import ApoderadosModificarTelefono from './apoderados/modificar_telefono'
  import ApoderadosAsignarAlumno from './apoderados/asignar_alumno'
  import ApoderadosAgregarTelefono from './apoderados/agregar_telefono'
  import ApoderadosAgregar from './apoderados/agregar'
    import ApoderadosAgregarDocumentos from './apoderados/documentos/agregar_documento'
    import ApoderadosRenombrarDocumento from './apoderados/documentos/renombrar_documento'
  import ApoderadosEditarParentesco from './apoderados/editar_parentesco';

import Aulas from './aulas/index'
  import AulasAula from './aulas/aula'
  import AulasModificar from './aulas/modificar'

import Pagos from './pagos/index'
  import PagosPagos from './pagos/pagos'
  import PagosCuotas from './pagos/cuotas'
  import PagosNuevaCuota from './pagos/nueva_cuota'
  import PagosModificarCuota from './pagos/modificar_cuota'
  import PagosModificarPago from './pagos/modificar_pago'
    import PagosEmisionSunatEmitirBoleta from './pagos/emision_sunat/emitir_boleta'

import Comprobantes from './comprobantes/index'
  import ComprobantesComunicarAnulacion from './comprobantes/comunicar_anulacion'

import Asistencia from './asistencia/index'
  import AsistenciaFechas from './asistencia/fechas'
  import AsistenciaTomar from './asistencia/tomar'

import Cuenta from './cuenta/index'
  import CuentaCambiarCorreo from './cuenta/cambiar_correo'
  import CuentaCambiarContrasena from './cuenta/cambiar_contrasena'

import Inicio from './inicio/index'

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Navigate,
  Outlet
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap-icons/font/bootstrap-icons.css';

console.clear()

function Proteger(props) {
  if (localStorage.getItem("token") == null) {
    return <Navigate to="/iniciar_sesion"></Navigate>
  } else {
    return <App {...props} />
  }
}

const router = createBrowserRouter([
  {
    path: "iniciar_sesion",
    element: <IniciarSesion />
  },
  {
    path: "/",
    element: <Proteger />,
    children: [
      {path: "exito", element: <Exito/>},
      {path: "error", element: <Error/>},
      {path: "no_autorizado", element: <NoAutorizado/>},

      {path: "", element: <Inicio />},

      {path: "alumnos", element: <Outlet />, children: [
        {path: "", element: <Alumnos/>},
        {path: "perfil/:id_alumno", element: <AlumnosPerfil/>},
        {path: "modificar_datos/:id_alumno", element: <AlumnosModificarDatos/>},
        {path: "agregar_documento/:id_alumno", element: <AlumnosAgregarDocumentos/>},
        {path: "renombrar_documento/:nombre_antiguo/:id_alumno", element: <AlumnosRenombrarDocumento/>},
        {path: "asignar_apoderado/:id_alumno", element: <AlumnosAsignarApoderado/>},
        {path: "asignar_apoderado_nuevo/:id_alumno", element: <AlumnosAsignarApoderadoNuevo/>},
        {path: "registrar/:id_aula", element: <AlumnosRegistrar />},
        {path: "registrar", element: <AlumnosRegistrar />},
        {path: "editar_parentesco/:id_alumno_apoderado", element: <AlumnosEditarParentesco />}
      ]},

      {path: "apoderados", element: <Outlet />, children: [
        {path: "", element: <Apoderados/>},
        {path: "perfil/:id_apoderado", element: <ApoderadosPerfil/>},
        {path: "modificar_datos/:id_apoderado", element: <ApoderadosModificarDatos/>},
        {path: "modificar_telefono/:id_telefono_apoderado", element: <ApoderadosModificarTelefono/>},
        {path: "agregar_telefono/:id_apoderado", element: <ApoderadosAgregarTelefono/>},
        {path: "asignar_alumno/:id_apoderado", element: <ApoderadosAsignarAlumno/>},
        {path: "agregar", element: <ApoderadosAgregar/>},
        {path: "documentos", element: <Outlet/>, children: [
          {path: "agregar_documento/:id_apoderado", element: <ApoderadosAgregarDocumentos/>},
          {path: "renombrar_documento/:nombre_antiguo/:id_apoderado", element: <ApoderadosRenombrarDocumento/>},
        ]},
        {path: "editar_parentesco/:id_alumno_apoderado", element: <ApoderadosEditarParentesco />}
      ]},

      {path: "aulas", element: <Outlet />, children: [
        {path: "", element: <Aulas/>},
        {path: "aula/:id_aula", element: <AulasAula/>},
        {path: "modificar/:id_aula", element: <AulasModificar />}
      ]},

      {path: "pagos", element: <Outlet />, children: [
        {path: "", element: <Pagos/>},
        {path: "pagos/:id_alumno", element: <PagosPagos/>},
        {path: "cuotas/:id_pago_alumno", element: <PagosCuotas/>},
        {path: "nueva_cuota/:id_pago_alumno", element: <PagosNuevaCuota />},
        {path: "modificar_cuota/:id_cuota_pago", element: <PagosModificarCuota />},
        {path: "modificar_pago/:id_pago_alumno", element: <PagosModificarPago />},
        {path: "emision_sunat", element: <Outlet/>, children: [
          {path: "emitir_boleta/:id_alumno", element: <PagosEmisionSunatEmitirBoleta/>}
        ]},
      ]},

      {path: "comprobantes", element: <Outlet/>, children: [
        {path: "", element: <Comprobantes/>},
        {path: "comunicar_anulacion/:id_comprobante_sunat", element: <ComprobantesComunicarAnulacion />},
      ]},

      {path: "asistencia", element: <Outlet />, children: [
        {path: "", element: <Asistencia/>},
        {path: "fechas/:id_aula", element: <AsistenciaFechas/>},
        {path: "tomar/:id_aula/:fecha", element: <AsistenciaTomar />}
      ]},

      {path: "cuenta", element: <Outlet />, children: [
        {path: "", element: <Cuenta />},
        {path: "cambiar_correo", element: <CuentaCambiarCorreo />},
        {path: "cambiar_contrasena", element: <CuentaCambiarContrasena />}
      ]}

    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
