import React, { useState, useEffect } from "react";
import { usePeticion } from "../../herramientas";
import { useNavigate, Link, useParams, Form } from "react-router-dom";

export default function AgregarDocumento() {
  let { nombre_antiguo, id_apoderado } = useParams();
  const navigate = useNavigate();
  const peticion = usePeticion();
  const [estado, setEstado] = useState("cargado");

  function handleSubmit(event) {
    event.preventDefault();
    setEstado("cargando");
    let formData = new FormData(event.currentTarget);
    formData.append("id_apoderado", id_apoderado);
    formData.append("nombre_antiguo", nombre_antiguo);
    peticion(
      "apoderados/documentos/renombrar/",
      {
        method: "POST",
        body: formData,
      },
      "Renombrar documento",
      "No han podido ser renombrado el documento satisfactoriamente",
      () => {
        navigate("../../perfil/" + id_apoderado);
      }
    );
  }

  function eliminar() {
    let resultado = window.confirm("¿Realmente desea eliminar este documento?")
    if (resultado){
      setEstado("cargando");
      let formData = new FormData();
      formData.append("id_apoderado", id_apoderado);
      formData.append("documento", nombre_antiguo);
      peticion(
        "apoderados/documentos/eliminar/",
        {
          method: "POST",
          body: formData,
        },
        "Eliminar documento",
        "No se ha podido eliminar el documento.",
        ()=>{
          navigate("../../perfil/"+id_apoderado)
        }
      )
    }
  }

  return (
    <>
      <h1>Modificar documento</h1>
      <form onSubmit={handleSubmit} class="row g-3">
        <div className="col-md-12">
          <label htmlFor="nombre_nuevo" className="form-label">
            Cambiar nombre
          </label>
          <input type="text" multiple className="form-control" id="nombre_nuevo" name="nombre_nuevo" defaultValue={nombre_antiguo} />
          <div class="form-text">Incluyendo la extención.</div>
        </div>
        <div className="col-md-12">
          <button type="submit" className="btn btn-warning me-3">
            <i class="bi bi-pencil"></i> Renombrar documento
          </button>
          <button onClick={eliminar} type="button" className="btn btn-danger me-3">
          <i class="bi bi-trash3-fill"></i> Eliminar documento
          </button>
          <Link to={"../../perfil/" + id_apoderado} role="button" className="btn btn-secondary">
            <i className="bi bi-x-lg"></i> Cancelar
          </Link>
        </div>
      </form>
    </>
  );
}
