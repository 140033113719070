import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, useParams, Link } from "react-router-dom";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [apoderados, setApoderados] = useState([]);
  const [apoderadosFiltrados, setApoderadosFiltrados] = useState([]);
  const [parentesco, setParentesco] = useState("")

  let { id_alumno } = useParams();

  const [busqueda, setBusqueda] = useState("");

  const peticion = usePeticion();
  const navigate = useNavigate();

  useEffect(function () {
    peticion("apoderados/lista/", {}, "Apoderados", "No ha podido ser cargada la lista general de apoderados", (json) => {
      setApoderados(json.apoderados);
      setApoderadosFiltrados(json.apoderados);
      setEstado("cargado");
    });
  }, []);

  function handleChangeBusqueda(event) {
    setBusqueda(event.target.value);
  }

  useEffect(
    function () {
      let asd = apoderados.filter((fila) => {
        if (fila.nombre_completo.toUpperCase().includes(busqueda.toUpperCase()) || fila.dni.includes(busqueda)) {
          return true;
        }
        return false;
      });
      setApoderadosFiltrados(asd);
    },
    [busqueda]
  );

  function handleSeleccionar(id_apoderado) {
    setEstado("cargando");
    let formData = new FormData();
    formData.append("id_alumno", id_alumno);
    formData.append("id_apoderado", id_apoderado);
    formData.append("parentesco", parentesco);
    peticion(
      "alumnos/asignar_apoderado/",
      {
        method: "POST",
        body: formData,
      },
      "Asignar apoderado",
      "No ha podido ser asignado el apoderado satisfactoriamente.",
      () => {
        navigate("../perfil/" + id_alumno);
      }
    );
  }

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Asignar apoderado</h1>
        <Link to={"../asignar_apoderado_nuevo/"+id_alumno} className="btn btn-success mb-3" role="button"><i className="bi bi-plus-lg"></i> Nuevo apoderado...</Link>
        <div className="mb-3">
          <label htmlFor="parentesco" className="form-label">
            Parentesco
          </label>
          <input type="text" className="form-control" value={parentesco} onChange={(e)=>setParentesco(e.target.value)} name="parentesco" id="parentesco" />
          <div className="form-text">Parentesco del apoderado con el alumno.</div>
        </div>
        <h3>Buscar apoderado</h3>
        <form className="mb-3 row g-3">
          <div className="col-12">
            <input type="text" value={busqueda} onChange={handleChangeBusqueda} placeholder="Nombre o DNI" className="form-control" />
          </div>
        </form>
        <div className="table-responsive">
          <table className="table table-sm text-center table-bordered table-striped align-middle table-hover">
            <thead>
              <tr>
                <th scope="col">DNI</th>
                <th scope="col">Nombre completo</th>
                <th scope="col">Cant. alum.</th>
                <th scope="col">Seleccionar</th>
              </tr>
            </thead>
            <tbody>
              {apoderadosFiltrados.map((apoderado) => (
                <tr key={apoderado.id_apoderado}>
                  <td>{apoderado.dni}</td>
                  <td>{apoderado.nombre_completo}</td>
                  <td>{apoderado.cantidad_alumnos}</td>
                  <td>
                    <button type="button" onClick={() => handleSeleccionar(apoderado.id_apoderado)} className="btn btn-primary btn-sm">
                      <i className="bi bi-check2"></i> Seleccionar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
