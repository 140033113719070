import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, useParams, Link } from "react-router-dom";

export default function Index() {
  const [estado, setEstado] = useState("cargado");
  const [parentesco, setParentesco] = useState("");

  let { id_alumno } = useParams();

  const [busqueda, setBusqueda] = useState("");

  const [dni, setDni] = useState("");
  const [nombreCompleto, setNombreCompleto] = useState("");

  const peticion = usePeticion();
  const navigate = useNavigate();

  function handleSubmit(event) {
    event.preventDefault();
    setEstado("cargando");
    let formData = new FormData(event.currentTarget);
    formData.append("id_alumno", id_alumno);
    peticion(
      "alumnos/asignar_apoderado_nuevo/",
      {
        method: "POST",
        body: formData,
      },
      "Asignar apoderado nuevo",
      "No ha podido ser creado y asignado el apoderado satisfactoriamente.",
      () => {
        navigate("../perfil/" + id_alumno);
      }
    );
  }

  function rellenarNombreCompleto() {
    peticion(
      "apoderados/consultar_nombre_completo_por_dni/" + dni,
      {},
      "Consultar nombre por DNI",
      "No se ha podido consultar el nombre del cliente por su DNI.",
      (json) => {
        setNombreCompleto(json.denominacion);
      }
    );
  }

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Asignar apoderado nuevo</h1>
        <form onSubmit={handleSubmit} className="row g-3">
          <h3 className="mb-0">Parentesco con el alumno</h3>
          <div className="col-md-12">
            <input type="text" className="form-control" name="parentesco" id="parentesco" />
            <div className="form-text">Parentesco del apoderado con el alumno</div>
          </div>
          <h3 className="mb-0">Datos apoderado nuevo</h3>
          <div className="col-md-4">
            <label htmlFor="dni" className="form-label">
              DNI
            </label>
            <input type="text" inputMode="tel" onChange={(e) => setDni(e.target.value)} value={dni} className="form-control" name="dni" id="dni" />
          </div>
          <div className="col-md-8">
            <label htmlFor="nombre_completo" className="form-label">
              Nombre completo
            </label>
            <div className="input-group">
              <button className="btn btn-outline-primary" onClick={rellenarNombreCompleto} type="button">
                <i className="bi bi-arrow-right-square"></i> Rellenar
              </button>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setNombreCompleto(e.target.value)}
                value={nombreCompleto}
                id="nombre_completo"
                name="nombre_completo"
              />
            </div>
          </div>
          <div className="col-md-12">
            <label htmlFor="correo" className="form-label">
              Email
            </label>
            <input type="email" className="form-control" name="correo" id="correo" />
          </div>
          <div className="col-md-12">
            <label htmlFor="observaciones" className="form-label">
              Observaciones
            </label>
            <textarea className="form-control" name="observaciones" id="observaciones" rows="5" />
          </div>
          <div className="col-md-12">
            <button type="submit" className="btn btn-success me-3">
              <i className="bi bi-plus-lg"></i> Agregar apoderado y asignar con alumno
            </button>
            <Link to={"../asignar_apoderado/"+id_alumno} role="button" className="btn btn-secondary">
              <i className="bi bi-x-lg"></i> Cancelar
            </Link>
          </div>
        </form>
      </>
    );
  }
}
