import { useEffect, useState } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link, useParams, Form } from "react-router-dom";

export default function Index() {
  const navigate = useNavigate();
  const [estado, setEstado] = useState("cargando");
  const [modulos, setModulos] = useState([]);
  const peticion = usePeticion();

  useEffect(() => {
    peticion("modulos_usuario/", {}, "Módulos de usuario", "No se han podido cargar los módulos del sistema al que el usuario tiene acceso", (json) => {
      setModulos(json.modulos);
      setEstado("cargado");
    });
  }, []);

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Inicio</h1>
        <div className="container p-0 h-100 mb-3">
          <div className="row g-3">
            {modulos.map((modulo) => (
              <div key={modulo.id_modulo} className="col-12 col-md-6 col-xl-4" style={{ cursor: "pointer" }}>
                <div
                  className="card w-100"
                  onClick={() => {
                    navigate(modulo.link);
                  }}
                >
                  <div className="card-body row w-100">
                    <div className="col-auto mb-0 h4">
                      <i className={modulo.bs_icono}></i>
                    </div>
                    <div className="col text-center d-flex justify-content-center">
                      <h5 className="my-auto align-middle">{modulo.modulo}</h5>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}
