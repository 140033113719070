import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, useParams, Link } from "react-router-dom";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [alumnos, setAlumnos] = useState([]);
  const [alumnosFiltrados, setAlumnosFiltrados] = useState([]);
  const [aulas, setAulas] = useState([]);
  const [parentesco, setParentesco] = useState("")

  let { id_apoderado } = useParams();

  const [busqueda, setBusqueda] = useState("");
  const [filtroAula, setFiltroAula] = useState(0);

  const peticion = usePeticion();
  const navigate = useNavigate();

  useEffect(function () {
    peticion("alumnos/lista/", {}, "Alumnos", "No ha podido ser cargada la lista general de alumnos", (json) => {
      setAlumnos(json.alumnos);
      setAlumnosFiltrados(json.alumnos);
      setAulas(json.aulas);
      setEstado("cargado");
    });
  }, []);

  function handleChangeBusqueda(event) {
    setBusqueda(event.target.value);
  }
  function handleChangeFiltroAula(event) {
    setFiltroAula(event.target.value);
  }

  useEffect(
    function () {
      let asd = alumnos.filter((fila) => {
        if (fila.nombre_completo.toUpperCase().includes(busqueda.toUpperCase()) || fila.dni.includes(busqueda)) {
          if (filtroAula != 0) {
            if (fila.id_aula == filtroAula) {
              return true;
            }
          } else {
            return true;
          }
        }
        return false;
      });
      setAlumnosFiltrados(asd);
    },
    [busqueda, filtroAula]
  );

  function handleSeleccionar(id_alumno) {
    setEstado("cargando");
    let formData = new FormData();
    formData.append("id_alumno", id_alumno);
    formData.append("id_apoderado", id_apoderado);
    formData.append("parentesco", parentesco);
    peticion(
      "apoderados/asignar_alumno/",
      {
        method: "POST",
        body: formData,
      },
      "Asignar alumno",
      "No ha podido ser asignado el alumno satisfactoriamente.",
      () => {
        navigate("../perfil/" + id_apoderado);
      }
    );
  }

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Asignar alumno</h1>
        <div className="mb-3">
          <label htmlFor="parentesco" className="form-label">
            Parentesco
          </label>
          <input type="text" className="form-control" value={parentesco} onChange={(e)=>setParentesco(e.target.value)} name="parentesco" id="parentesco" />
          <div className="form-text">Parentesco del apoderado con el alumno.</div>
        </div>
        <h3>Buscar alumno</h3>
        <form className="mb-3 row g-3">
          <div className="col-12 col-md-8">
            <input type="text" value={busqueda} onChange={handleChangeBusqueda} placeholder="Nombre o DNI" className="form-control" />
          </div>
          <div className="col-12 col-md-4">
            <select className="form-select" value={filtroAula} onChange={handleChangeFiltroAula}>
              <option selected value={0}>
                Todas las aulas
              </option>
              <optgroup label="Inicial">
              {aulas.map((aula) => (
                aula.nivel == "Inicial" ? <option value={aula.id_aula} key={aula.id_aula}>{`${aula.nombre}`}</option> : null
              ))}
              </optgroup>
              <optgroup label="Primaria">
              {aulas.map((aula) => (
                aula.nivel == "Primaria" ? <option value={aula.id_aula} key={aula.id_aula}>{`${aula.nombre}`}</option> : null
              ))}
              </optgroup>
              <optgroup label="Secundaria">
              {aulas.map((aula) => (
                aula.nivel == "Secundaria" ? <option value={aula.id_aula} key={aula.id_aula}>{`${aula.nombre}`}</option> : null
              ))}
              </optgroup>
            </select>
          </div>
        </form>
        <div className="table-responsive">
          <table className="table table-sm text-center table-bordered table-striped align-middle table-hover">
            <thead>
              <tr>
                <th scope="col">DNI</th>
                <th scope="col">Nombre completo</th>
                <th scope="col">Nivel</th>
                <th scope="col">Aula</th>
                <th scope="col">Seleccionar</th>
              </tr>
            </thead>
            <tbody>
              {alumnosFiltrados.map((alumno) => (
                <tr key={alumno.id_alumno}>
                  <td>{alumno.dni}</td>
                  <td>{alumno.nombre_completo}</td>
                  <td>{alumno.nivel}</td>
                  <td>{alumno.nombre}</td>
                  <td>
                    <button type="button" onClick={()=>handleSeleccionar(alumno.id_alumno)} className="btn btn-primary btn-sm">
                      <i className="bi bi-check2"></i> Seleccionar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
