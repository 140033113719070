import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link, useParams } from "react-router-dom";
import { API_URL } from "./../conexion";

export default function Index(props) {
  let { id_apoderado } = useParams();

  const [datos, setDatos] = useState({});
  const [alumnos, setAlumnos] = useState([]);
  const [estado, setEstado] = useState("cargando");
  const [telefonos, setTelefonos] = useState([]);
  const [documentos, setDocumentos] = useState([]);

  const peticion = usePeticion();

  function montar() {
    peticion("apoderados/perfil/" + id_apoderado, {}, "Apoderado", "No se ha podido cargar el perfil del apoderado", (json) => {
      setDatos(json.datos);
      setAlumnos(json.alumnos);
      setTelefonos(json.telefonos);
      setDocumentos(json.documentos);
      setEstado("cargado");
    });
  }

  useEffect(montar, []);

  function handleDesasignar(id_alumno_apoderado) {
    var resultado = window.confirm("Está seguro que desea desasignar a este apoderado de este alumno");
    if (resultado) {
      setEstado("cargando");
      let formData = new FormData();
      formData.append("id_alumno_apoderado", id_alumno_apoderado);
      formData.append("id_apoderado", id_apoderado);
      peticion(
        "apoderados/desasignar_alumno/",
        {
          method: "POST",
          body: formData,
        },
        "Desasignar alumno",
        "No ha podido ser desasignado el alumno del apoderado satisfactoriamente.",
        () => {
          setEstado("cargado");
          montar();
        }
      );
    }
  }

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Perfil apoderado</h1>
        <p className="lead">{datos.nombre_completo}</p>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <h2>Datos</h2>
            <Link role="button" className="btn btn-warning btn-sm" to={"../modificar_datos/" + id_apoderado}>
              <i className="bi bi-pencil"></i> Modificar
            </Link>
            <table className="table align-middle text-center">
              <tbody>
                <tr>
                  <th scope="row">DNI</th>
                  <td>{datos.dni}</td>
                </tr>
                <tr>
                  <th scope="row">Email</th>
                  <td>
                    <a href={"mailto:" + datos.correo} target="_blank">
                      {datos.correo}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <h2>Observaciones</h2>
            <p>
              {datos.observaciones !== null ? (
                datos.observaciones.split("\n").map((parrafo) => {
                  return (
                    <>
                      {parrafo}
                      <br />
                    </>
                  );
                })
              ) : (
                <i>No hay observaciones</i>
              )}
            </p>
            <h2>Documentos</h2>
            <Link role="button" className="btn btn-success btn-sm mb-3" to={"../documentos/agregar_documento/" + id_apoderado}>
              <i className="bi bi-plus-lg"></i> Agregar
            </Link>
            <div className="card">
              <ul className="list-group list-group-flush">
                {documentos.map((documento) => (
                  <li className="list-group-item" key={documento.nombre}>
                    <form target="_blank" action={API_URL + "apoderados/documentos/ver/"} method="post">
                      <input type="hidden" name="documento" value={documento.nombre}></input>
                      <input type="hidden" name="id_apoderado" value={id_apoderado}></input>
                      <div className="btn-group">
                        <button
                          type="submit"
                          target="_blank"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="bi bi-file-earmark"></i> Ver
                        </button>
                        <Link role="button" to={"../documentos/renombrar_documento/" + documento.nombre + "/" + id_apoderado} className="btn btn-outline-warning btn-sm">
                        <i className="bi bi-pencil"></i>
                      </Link>
                      </div>
                      {" " + documento.nombre}
                    </form>
                    
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-12">
          <h2>Teléfonos</h2>
            <Link role="button" to={"../agregar_telefono/" + id_apoderado} className="btn btn-success btn-sm mb-3">
              <i className="bi bi-plus-lg"></i> Agregar teléfono
            </Link>
            <table className="table align-middle text-center">
              <tbody>
                {telefonos.map((telefono) => (
                  <tr key={telefono.id_telefono_apoderado}>
                    <th scope="row">{telefono.etiqueta}</th>
                    <td>{telefono.telefono}</td>
                    <td>
                      <div className="btn-group">
                        <Link role="button" to={`../modificar_telefono/${telefono.id_telefono_apoderado}`} className="btn btn-warning btn-sm">
                          <i className="bi bi-pencil"></i>
                        </Link>
                        <a role="button" target="_blank" href={"https://wa.me/" + telefono.telefono} className="btn btn-success btn-sm">
                          <i className="bi bi-whatsapp"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <h2>Alumnos</h2>
            <Link role="button" className="btn btn-success btn-sm mb-3" to={"../asignar_alumno/" + id_apoderado}>
              <i className="bi bi-plus-lg"></i> Asignar alumno
            </Link>
            {alumnos.map((alumno) => (
              <div className="card mb-3" key={alumno.id_alumno}>
                <div className="card-body">
                  <h5 className="card-title">{alumno.nombre_completo + " "}</h5>
                  <h6 className="card-subtitle mb-2 text-muted">{alumno.parentesco}</h6>
                  <div className="btn-group mb-2">
                    <Link to={"../../alumnos/perfil/" + alumno.id_alumno} role="button" className="btn btn-primary btn-sm">
                      <i className="bi bi-person-circle"></i> Perfil
                    </Link>
                    <Link to={`../editar_parentesco/${alumno.id_alumno_apoderado}`} role="button" className="btn btn-outline-warning btn-sm">
                      <i className="bi bi-pencil"></i>
                    </Link>
                    <button onClick={() => handleDesasignar(alumno.id_alumno_apoderado)} role="button" className="btn btn-outline-danger btn-sm">
                      <i className="bi bi-x-lg"></i>
                    </button>
                  </div>
                  {/* <p className="card-text mb-0">DNI: {alumno.dni}</p> */}
                  <p className="card-text mb-0">Aula: {`${alumno.nombre} ${alumno.nivel}`}</p>
                  <p className="card-text mb-0">Saldo: S/ {alumno.saldo}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}
