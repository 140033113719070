import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link, useParams, Form } from "react-router-dom";

export default function RegistrarAlumno() {
  let { id_pago_alumno } = useParams();
  const peticion = usePeticion();
  const navigate = useNavigate();

  const [estado, setEstado] = useState("cargando");
  const [metodosPago, setMetodosPagos] = useState([]);
  const [pago, setPago] = useState({})
  const [today] = useState(new Date())

  useEffect(function () {
    peticion("pagos/cuotas/"+id_pago_alumno, {}, "Nueva cuota", "No ha podido ser cargados los datos para la nueva cuota.", (json) => {
      setMetodosPagos(json.metodos_pago);
      setPago(json.pago_alumno)
      setEstado("cargado");
    });
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    setEstado("cargando")
    let formData = new FormData(event.currentTarget);
    formData.append("id_pago_alumno", id_pago_alumno)
    peticion(
      "pagos/nueva_cuota/",
      {
        method: "POST",
        body: formData,
      },
      "Nuevo cuota",
      "La cuota no ha podido registrase de forma satisfactoria.",
      (json) => {
        navigate("../cuotas/" + id_pago_alumno);
      }
    );
  }

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Nueva cuota</h1>
        <form onSubmit={handleSubmit} className="row g-3">
          <div className="col-md-6">
            <label htmlFor="monto" className="form-label">
              Monto
            </label>
            <input type="number" step={0.01} min={0.01} defaultValue={pago.precio} required className="form-control" id="monto" name="monto" />
          </div>
          <div className="col-md-6">
            <label htmlFor="id_metodo_pago" className="form-label">
              Método de pago
            </label>
            <select id="id_metodo_pago" name="id_metodo_pago" className="form-select">
              {metodosPago.map((metodo)=><option value={metodo.id_metodo_pago}>{metodo.nombre}</option>)}
            </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="fecha" className="form-label">
              Fecha
            </label>
            <input type="date" defaultValue={today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2)} required className="form-control" id="fecha" name="fecha" />
          </div>
          <div className="col-md-6">
            <label htmlFor="imagen_comprobante" className="form-label">
              Imagen de comprobante
            </label>
            <input type="file" accept="image/*" className="form-control" name="imagen_comprobante" id="imagen_comprobante" />
          </div>
          <div className="col-md-12">
            <label htmlFor="observaciones" className="form-label">
              Observaciones
            </label>
            <textarea className="form-control" name="observaciones" id="observaciones" rows="5" />
          </div>
          <div className="col-md-12">
            <button type="submit" className="btn btn-success me-3">
              <i className="bi bi-plus-lg"></i> Registrar nueva cuota
            </button>
            <button type="button" onClick={() => navigate(-1)} className="btn btn-secondary">
              <i className="bi bi-x-lg"></i> Cancelar
            </button>
          </div>
        </form>
      </>
    );
  }
}
