import React, { useState, useEffect } from "react";
import { useFecha, usePeticion } from "../../herramientas";
import { useNavigate, Link, useParams } from "react-router-dom";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [alumno, setAlumno] = useState({});
  const [apoderados, setApoderados] = useState([]);
  const [cuotas, setCuotas] = useState([]);
  const [pago, setPago] = useState({});
  const [series, setSeries] = useState([]);
  const [numero, setNumero] = useState();
  const [today] = useState(new Date());
  const [total, setTotal] = useState(0);

  const [dni, setDni] = useState("");
  const [denominacion, setDenominacion] = useState("");
  
  const { id_alumno } = useParams();
  const peticion = usePeticion();
  const navigate = useNavigate();
  const aFecha = useFecha();

  useEffect(inicializar, []);

  function inicializar() {
    setEstado("cargando")
    peticion("pagos/emision_sunat/cuotas_alumno/" + id_alumno, {}, "Emitir boleta sunat", "No se ha podido cargar las cuotas del alumno.", (json) => {
      setAlumno(json.alumno);
      let asd = json.cuotas;
      asd.forEach((element) => {
        element.descripcion = `${element.estado} ${element.concepto} ${json.alumno.nombre_aula} ${json.alumno.nombre_completo}`
        element.boleta = true;
      });
      setCuotas(asd);
      setApoderados(json.apoderados);
      setSeries(json.series);
      setNumero(json.numero);
      setEstado("seleccionar");
    });
  }

  function rellenarDenominacion() {
    peticion(
      "pagos/emision_sunat/consultar_denominacion_por_dni/" + dni,
      {},
      "Consultar nombre por DNI",
      "No se ha podido consultar el nombre del cliente por su DNI.",
      (json) => {
        setDenominacion(json.denominacion);
      }
    );
  }

  function handleCuotaBoleta(id_cuota_pago_asd) {
    var asdasd = [...cuotas];
    asdasd.forEach((cuota) => {
      if (id_cuota_pago_asd == cuota.id_cuota_pago) {
        cuota.boleta = !cuota.boleta;
      }
    });
    setCuotas(asdasd);
  }

  useEffect(function () {
    let nuevoTotal = 0.00;
    cuotas.forEach(cuota=>{
      if (cuota.boleta) {
        nuevoTotal += parseFloat(cuota.monto)
      }
    })
    setTotal(nuevoTotal.toFixed(2))
  }, [cuotas])

  function handleSubmit(event) {
    event.preventDefault();
    if (window.confirm("¿Está seguro de emitir está boleta?")) {
      setEstado("cargando");
      let formData = new FormData(event.currentTarget);
      formData.append("items", JSON.stringify(cuotas.filter(cuota=>cuota.boleta)))
      formData.append("total", total)
      peticion(
        "pagos/emision_sunat/emitir_boleta/",
        {
          method: "POST",
          body: formData,
        },
        "Emitir boleta SUNAT",
        "La boleta no ha podido ser emitida correctamente.",
        {
          titulo: "Emitir boleta SUNAT",
          mensaje: "La boleta ha sido emitida exitosamente.",
          botones: [
            {
              nombre: "Ver pagos del alumno",
              direccion: "../pagos/pagos/" + id_alumno,
              color: "primary",
            },
          ],
        }
      );
    }
  }

  function handleDescripcion(id_cuota_pago, descripcion) {
    let asd = [...cuotas]
    asd.forEach(cuota => {
      if (cuota.id_cuota_pago == id_cuota_pago) {
        cuota.descripcion = descripcion
      }
    })
    setCuotas(asd)
  }

  function handleMonto(id_cuota_pago, monto) {
    let asd = [...cuotas]
    asd.forEach(cuota => {
      if (cuota.id_cuota_pago == id_cuota_pago) {
        cuota.monto = monto
      }
    })
    setCuotas(asd)
  }

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (estado === "seleccionar") {
    return (
      <>
        <h1>Emitir boleta SUNAT</h1>
        <h2>Seleccionar cuotas a boletear</h2>
        <p>Solo se muestran las cuotas NO emitidas en SUNAT</p>
        <div className="d-flex justify-content-end mb-3">
          <button type="button" onClick={() => navigate(-1)} className="btn btn-secondary me-3">
            <i className="bi bi-x-lg"></i> Cancelar
          </button>
          <button type="button" onClick={() => setEstado("formulario")} className="btn btn-success ">
            <i className="bi bi-receipt"></i> Terminar selección
          </button>
        </div>
        <div className="table-responsive">
          <table className="table align-middle table-sm text-center table-bordered">
            <thead>
              <tr>
                <th scope="col">Selec.</th>
                <th scope="col">Fecha</th>
                <th scope="col">Concepto</th>
                <th scope="col">Monto</th>
              </tr>
            </thead>
            <tbody>
              {cuotas.map((cuota) => (
                <tr key={cuota.id_cuota_pago}>
                  <td>
                    <input class="form-check-input" checked={cuota.boleta} type="checkbox" onChange={(e) => handleCuotaBoleta(cuota.id_cuota_pago)} />
                  </td>
                  <td>{aFecha(cuota.fecha)}</td>
                  <td>{cuota.concepto}</td>
                  <td>S/ {cuota.monto}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  } else {
    return (
      <>
        <h1>Emitir boleta SUNAT</h1>
        <h2>Alumno</h2>
        <table className="table align-middle text-center table-bordered">
          <tbody>
            <tr>
              <th scope="row">Alumno</th>
              <td>{alumno.nombre_completo}</td>
            </tr>
            <tr>
              <th scope="row">Aula</th>
              <td>{alumno.nombre_aula}</td>
            </tr>
          </tbody>
        </table>
        <h2>Boleta</h2>
        <form onSubmit={handleSubmit} className="row g-3">
          <h3 className="mb-0">Datos</h3>
          <div className="col-md-4">
            <label htmlFor="cliente_numero_de_documento" className="form-label">
              DNI del cliente
            </label>
            <input
              onChange={(e) => setDni(e.target.value)}
              value={dni}
              list="lista_dni"
              type="text"
              inputMode="tel"
              required
              className="form-control"
              id="cliente_numero_de_documento"
              name="cliente_numero_de_documento"
            />
            <datalist id="lista_dni">
              {apoderados.map((apoderado) => (
                <option key={apoderado.id_apoderado} value={apoderado.dni}>
                  {apoderado.nombre_completo}
                </option>
              ))}
            </datalist>
          </div>
          <div className="col-md-8">
            <label htmlFor="cliente_denominacion" className="form-label">
              Denominación del cliente
            </label>
            <div className="input-group">
              <button className="btn btn-outline-primary" onClick={rellenarDenominacion} type="button">
                <i className="bi bi-arrow-right-square"></i> Rellenar
              </button>
              <input
                type="text"
                required
                value={denominacion}
                onChange={(e) => setDenominacion(e.target.value)}
                className="form-control"
                id="cliente_denominacion"
                name="cliente_denominacion"
              />
            </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="serie" className="form-label">
              Serie
            </label>
            <select id="serie" name="serie" className="form-select">
              {series.map((serie) => (
                <option key={serie.id_serie_comprobante_sunat} value={serie.serie}>
                  {serie.serie}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="fecha_de_emision" className="form-label">
              Fecha de emisión
            </label>
            <input
              type="date"
              required
              defaultValue={today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2)}
              className="form-control"
              id="fecha_de_emision"
              name="fecha_de_emision"
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">Número</label>
            <input type="text" defaultValue={numero} name="numero" className="form-control" />
          </div>
          <h3 className="mb-0">Ítems</h3>
          {cuotas
            .filter((cuota) => cuota.boleta)
            .map((cuota) => (
              <React.Fragment key={cuota.id_cuota_pago}>
                <div className="col-md-8">
                  <input
                    type="text"
                    value={cuota.descripcion}
                    onChange={(e)=>handleDescripcion(cuota.id_cuota_pago, e.target.value)}
                    required
                    className="form-control"
                  />
                </div>
                <div className="col-md-4">
                  <div className="input-group">
                    <span className="input-group-text">S/</span>
                    <input type="number" step={0.01} min={0.01} value={cuota.monto} onChange={(e)=>handleMonto(cuota.id_cuota_pago, e.target.value)} required className="form-control" />
                  </div>
                </div>
              </React.Fragment>
            ))}
          <div className="col-md-8 text-end">
          </div>
          <div className="col-md-4">
            <label className="form-label">Total</label>
            <div className="input-group">
              <span className="input-group-text">S/</span>
              <input type="number" value={total} required disabled readOnly className="form-control" name="total" id="total" />
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-end">
            <button type="button" onClick={() => {inicializar()}} className="btn btn-secondary me-3">
              <i className="bi bi-x-lg"></i> Cancelar
            </button>
            <button type="submit" className="btn btn-success ">
              <i className="bi bi-upload"></i> Emitir boleta SUNAT
            </button>
          </div>
        </form>
      </>
    );
  }
}
