import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link } from "react-router-dom";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [aulas, setAulas] = useState([]);

  const peticion = usePeticion();

  useEffect(function () {
    peticion("aulas/lista/", {}, "Aulas", "No ha podido ser cargadas las aulas", (json) => {
      setAulas(json.aulas);
      setEstado("cargado");
    });
  }, []);

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Aulas</h1>
        <h2>Inicial</h2>
        <div class="row g-3 mb-3">
          {aulas.map((aula) => {
            if (aula.nivel == "Inicial") {
              return <div class="col-12 col-md-6 col-lg-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">
                  {aula.nombre}
                  </h5>
                  <h6 class="card-subtitle mb-2 text-muted">{aula.cantidad_alumnos} alumnos</h6>
                  <h6 class="card-subtitle mb-2 text-muted">{aula.cantidad_matricula2023} alumnos matriculados 2023</h6>
                  <Link class="btn btn-primary" role="button" to={"aula/"+aula.id_aula}>
                    <i class="bi bi-easel3"></i> Ver aula
                  </Link>
                </div>
              </div>
            </div>;
            }
          })}
        </div>
        <h2>Primaria</h2>
        <div class="row g-3 mb-3">
          {aulas.map((aula) => {
            if (aula.nivel == "Primaria") {
              return <div class="col-12 col-md-6 col-lg-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">
                    {aula.nombre}
                  </h5>
                  <h6 class="card-subtitle mb-2 text-muted">{aula.cantidad_alumnos} alumnos</h6>
                  <h6 class="card-subtitle mb-2 text-muted">{aula.cantidad_matricula2023} alumnos matriculados 2023</h6>
                  <Link class="btn btn-primary" role="button" to={"aula/"+aula.id_aula}>
                    <i class="bi bi-easel3"></i> Ver aula
                  </Link>
                </div>
              </div>
            </div>;
            }
          })}
        </div>
        <h2>Secundaria</h2>
        <div class="row g-3 mb-3">
          {aulas.map((aula) => {
            if (aula.nivel == "Secundaria") {
              return <div class="col-12 col-md-6 col-lg-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">
                  {aula.nombre}
                  </h5>
                  <h6 class="card-subtitle mb-2 text-muted">{aula.cantidad_alumnos} alumnos</h6>
                  <h6 class="card-subtitle mb-2 text-muted">{aula.cantidad_matricula2023} alumnos matriculados 2023</h6>
                  <Link class="btn btn-primary" role="button" to={"aula/"+aula.id_aula}>
                    <i class="bi bi-easel3"></i> Ver aula
                  </Link>
                </div>
              </div>
            </div>;
            }
          })}
        </div>
      </>
    );
  }
}
