import React, { useState, useEffect } from "react";
import { useFecha, usePeticion } from "../herramientas";
import { useNavigate, Link, useParams } from "react-router-dom";
import { API_URL } from "../conexion";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [datos, setDatos] = useState({});
  const [cuotas, setCuotas] = useState([]);
  const [pago, setPago] = useState({});

  const peticion = usePeticion();
  const navigate = useNavigate();
  const afecha = useFecha();
  let { id_pago_alumno } = useParams();

  function inicializar() {
    peticion("pagos/cuotas/" + id_pago_alumno, {}, "Cuotas", "No se han podido cargar las cuotas del pago del alumno", (json) => {
      setDatos(json.datos);
      setCuotas(json.cuotas);
      setPago(json.pago_alumno);
      setEstado("cargado");
    });
  }

  useEffect(inicializar, []);

  function eliminarCuota(id_cuota_pago) {
    if (window.confirm("¿Está seguro de eliminar este pago?")) {
      setEstado("cargando");
      peticion(
        "pagos/eliminar_cuota/" + id_cuota_pago,
        { method: "DELETE" },
        "Eliminar cuota de pago",
        "La cuota no ha podido ser eliminada satisfactoriamente.",
        () => {
          inicializar();
        }
      );
    }
  }

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1><Link type="button" role="button" className="btn btn-outline-secondary me-3" to={"../pagos/"+datos.id_alumno}>
          <i className="bi bi-chevron-left"></i>
        </Link>{datos.nombre_completo}</h1>
        <p className="lead">{pago.concepto} <span className={"mb-1 badge text-bg-"+pago.bs_color}>{pago.estado}</span></p>
        <Link className="btn btn-warning btn-sm mb-3" role="button" to={"../modificar_pago/" + id_pago_alumno}>
          <i className="bi bi-pencil"></i> Modificar estado
        </Link>
        <h2>Cuotas</h2>
        <div className="d-flex justify-content-between">
        <Link to={"../nueva_cuota/" + id_pago_alumno} role="button" className="btn btn-success mb-3">
          <i className="bi bi-plus-lg"></i> Nueva cuota
        </Link>
          <Link role="button" to={"../emision_sunat/emitir_boleta/"+ datos.id_alumno } className="btn btn-primary mb-3">
          <i className="bi bi-receipt"></i> Emitir boleta SUNAT
          </Link>
        </div>
        {cuotas.map((cuota) => (
          <div className="card mb-3" key={cuota.id_cuota_pago}>
            <div className="card-body">
              <h4 className="card-title">S/ {cuota.monto}</h4>
              <h5 className="card-subtitle mb-1 text-muted">{cuota.metodo_pago}</h5>
              <p className="mb-1">{cuota.id_comprobante_sunat != null ? <span className="badge bg-success">Comprobante SUNAT emitido</span> : <span className="badge bg-secondary">Comprobante SUNAT no emitido</span>}</p>
              <em>{afecha(cuota.fecha)}</em>
              <br />
              {cuota.observaciones}
            </div>
            <div className="card-footer text-muted d-flex">
              <Link className="btn btn-warning me-3 btn-sm" role="button" to={"../modificar_cuota/" + cuota.id_cuota_pago}>
                <i className="bi bi-pencil"></i> Modificar
              </Link>
              {cuota.imagen_comprobante ? (
                <form target="_blank" action={API_URL + "pagos/ver_imagen_comprobante/"} method="POST">
                  <input type="hidden" name="id_cuota_pago" value={cuota.id_cuota_pago}></input>
                  <button type="submit" className="btn btn-info me-3 btn-sm" role="button">
                    <i className="bi bi-file-earmark-image"></i> Ver imagen comprobante
                  </button>
                </form>
              ) : null}
              {cuota.id_comprobante_sunat != null ? (
                <a className="btn btn-info btn-sm me-3" target="_blank" href={API_URL + "comprobantes/pdf/" + cuota.id_comprobante_sunat}>
                  <i className="bi bi-file-earmark-pdf-fill"></i> Ver PDF boleta SUNAT
                </a>
              ) : (
                <>
                  <button className="btn btn-danger btn-sm me-3" type="button" onClick={() => eliminarCuota(cuota.id_cuota_pago)}>
                    <i className="bi bi-trash3-fill"></i> Eliminar cuota
                  </button>
                </>
              )}
            </div>
          </div>
        ))}
      </>
    );
  }
}
