import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link, useParams, Form } from "react-router-dom";

export default function ModificarDatos() {
  let { id_aula } = useParams();
  const peticion = usePeticion();
  const navigate = useNavigate();

  const [estado, setEstado] = useState("cargando");
  const [aula, setAula] = useState({});
  const [usuarios, setUsuarios] = useState([])

  useEffect(function () {
    peticion("aulas/aula/" + id_aula, {}, "Modificar aula", "No ha podido ser cargada la información del aula", (json) => {
      setAula(json.aula);
      setEstado("cargado");
      setUsuarios(json.usuarios);
    });
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    setEstado("cargando");
    let formData = new FormData(event.currentTarget);
    formData.append("id_aula", id_aula);
    peticion(
      "aulas/modificar/",
      {
        method: "PUT",
        body: formData,
      },
      "Modificar datos del aula",
      "No ha sido modificada el aula satisfatoriamente",
      () => {
        navigate("../aula/" + id_aula);
      }
    );
  }

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Modificar aula</h1>
        <p className="lead">{aula.nombre}</p>
        <form onSubmit={handleSubmit} className="row g-3">
          <div className="col-md-12">
            <label htmlFor="nombre" className="form-label">
              Nombre
            </label>
            <input type="text" required defaultValue={aula.nombre} className="form-control" id="nombre" name="nombre" />
          </div>
          <div className="col-md-12">
            <label htmlFor="id_tutor" className="form-label">
              Tutor
            </label>
            <select className="form-select" name="id_tutor" defaultValue={aula.id_tutor}>
              {usuarios.map(usuario=>(<option key={usuario.id_usuario} value={usuario.id_usuario}>{usuario.nombre}</option>))}
            </select>
          </div>
          <div className="col-md-12">
            <label htmlFor="id_auxiliar" className="form-label">
              Auxiliar
            </label>
            <select className="form-select" name="id_auxiliar" defaultValue={aula.id_auxiliar}>
              {usuarios.map(usuario=>(<option key={usuario.id_usuario} value={usuario.id_usuario}>{usuario.nombre}</option>))}
            </select>
          </div>
          <div className="col-md-12">
            <label htmlFor="observaciones" className="form-label">
              Observaciones
            </label>
            <textarea className="form-control" defaultValue={aula.observaciones} name="observaciones" id="observaciones" rows="5" />
          </div>
          <div className="col-md-12">
            <button type="submit" className="btn btn-warning me-3">
              <i className="bi bi-pencil"></i> Guardar cambios
            </button>
            <Link to={"../aula/" + id_aula} role="button" className="btn btn-secondary">
              <i className="bi bi-x-lg"></i> Cancelar
            </Link>
          </div>
        </form>
      </>
    );
  }
}
