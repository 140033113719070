import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link } from "react-router-dom";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [apoderados, setApoderados] = useState([]);
  const [apoderadosFiltrados, setApoderadosFiltrados] = useState([]);

  const [busqueda, setBusqueda] = useState("");

  const peticion = usePeticion();
  const navigate = useNavigate();

  useEffect(function () {
    peticion("apoderados/lista/", {}, "Apoderados", "No ha podido ser cargada la lista general de apoderados", (json) => {
      let asd = json.apoderados
      asd.forEach(apoderado => {
        apoderado.strTelefonos = `Teléfonos:
`;
        apoderado.telefonos.forEach(telefono=>{
          apoderado.strTelefonos += telefono+`
`;
        })
        apoderado.strTelefonos = apoderado.strTelefonos.slice(0, -1)
      });
      setApoderados(asd);
      setApoderadosFiltrados(asd);
      setEstado("cargado");
    });
  }, []);

  function handleChangeBusqueda(event) {
    setBusqueda(event.target.value);
  }

  useEffect(
    function () {
      let asd = apoderados.filter((fila) => {
        if (fila.nombre_completo.toUpperCase().includes(busqueda.toUpperCase()) || fila.dni.includes(busqueda) || fila.telefonos.includes(busqueda)) {
          return true;
        }
        for (let i = 0; i < fila.telefonos.length; i++) {
          const element = fila.telefonos[i];
          if (element.includes(busqueda)) {
            return true;
          }
        }
        return false;
      });
      setApoderadosFiltrados(asd);
    },
    [busqueda]
  );

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Apoderados</h1>
        <p>Cantidad apoderados filtrados <span className="badge bg-secondary">{apoderadosFiltrados.length}</span></p>
        <Link role="button" to="agregar" className="btn btn-success me-3 mb-3">
        <i className="bi bi-plus-lg"></i> Agregar apoderado
        </Link>
        <form className="mb-3 row g-3">
          <div className="col-12">
            <input type="text" value={busqueda} onChange={handleChangeBusqueda} placeholder="Nombre, DNI o teléfono" className="form-control" />
          </div>
        </form>
        <div className="table-responsive">
          <table className="table table-sm text-center table-bordered table-striped align-middle table-hover">
            <thead>
              <tr>
                <th scope="col">Perfil</th>
                <th scope="col">DNI</th>
                <th scope="col">Nombre completo</th>
                <th scope="col"className="px-3"><i className="bi bi-telephone-fill"></i></th>
                <th scope="col">Cant. alum.</th>
              </tr>
            </thead>
            <tbody>
              {apoderadosFiltrados.map((apoderado) => (
                <tr key={apoderado.id_apoderado}>
                  <td>
                    <Link role="button" to={"perfil/" + apoderado.id_apoderado} className="btn btn-primary btn-sm">
                      <i className="bi bi-person-circle"></i>
                    </Link>
                  </td>
                  <td>{apoderado.dni}</td>
                  <td>{apoderado.nombre_completo}</td>
                  <td title={apoderado.strTelefonos} style={{cursor: "help"}}><i className="bi bi-telephone"></i></td>
                  {apoderado.cantidad_alumnos != 0 ? <td>{apoderado.cantidad_alumnos}</td> : <td className="table-warning">{apoderado.cantidad_alumnos}</td>}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
