import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link, useParams, Form } from "react-router-dom";

export default function Index() {
  const peticion = usePeticion();
  const navigate = useNavigate();

  const [estado, setEstado] = useState("cargando");
  const [usuario, setUsuario] = useState({});

  useEffect(function () {
    peticion("cuenta/datos/", {}, "Datos de la cuenta", "No se han podido cargar los datos de la cuenta", (json) => {
      setUsuario(json.usuario);
      setEstado("cargado");
    });
  }, []);

  if (estado === "cargando") {
    return (
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Administración de cuenta de usuario</h1>
        <p className="lead">{usuario.nombre}</p>
        <p>Correo: {usuario.correo}</p>
        <div className="container p-0 h-100 mb-3">
          <div className="row g-3">
            <div className="col-12 col-md-6 col-xl-4" style={{ cursor: "pointer" }}>
              <div
                className="card w-100"
                onClick={() => {
                  navigate("cambiar_correo");
                }}
              >
                <div className="card-body row w-100">
                  <div className="col-auto mb-0 h4">
                    <i class="bi bi-envelope"></i>
                  </div>
                  <div className="col text-center d-flex justify-content-center">
                    <h5 className="my-auto align-middle">Cambiar correo electrónico</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4" style={{ cursor: "pointer" }}>
              <div
                className="card w-100"
                onClick={() => {
                  navigate("cambiar_contrasena");
                }}
              >
                <div className="card-body row w-100">
                  <div className="col-auto mb-0 h4">
                    <i class="bi bi-person-lock"></i>
                  </div>
                  <div className="col text-center d-flex justify-content-center">
                    <h5 className="my-auto align-middle">Cambiar contraseña</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
