import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link, useParams, Form } from "react-router-dom";

export default function RegistrarAlumno() {
  let { id_aula } = useParams();
  const peticion = usePeticion();
  const navigate = useNavigate();

  const [estado, setEstado] = useState("cargando");
  const [aulas, setAulas] = useState([]);

  useEffect(function () {
    peticion("aulas/lista/", {}, "Registrar alumno", "No ha podido ser cargadas las aulas", (json) => {
      setAulas(json.aulas);
      setEstado("cargado");
    });
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    peticion(
      "alumnos/registrar/",
      {
        method: "POST",
        body: formData,
      },
      "Registrar alumno",
      "No ha sido registrado el alumno satisfatoriamente",
      (json) => {
        navigate("../perfil/" + json.id_alumno);
      }
    );
  }

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Registrar alumno</h1>
        <form onSubmit={handleSubmit} className="row g-3">
          <div className="col-md-9">
            <label htmlFor="nombre_completo" className="form-label">
              Nombre completo
            </label>
            <input type="text" required className="form-control" id="nombre_completo" name="nombre_completo" />
          </div>
          <div className="col-md-3">
            <label htmlFor="id_aula" className="form-label">
              Grado
            </label>
            <select id="id_aula" name="id_aula" defaultValue={id_aula} className="form-select">
              <optgroup label="Inicial">
                {aulas.map((aula) => (aula.nivel == "Inicial" ? <option value={aula.id_aula} key={aula.id_aula}>{`${aula.nombre}`}</option> : null))}
              </optgroup>
              <optgroup label="Primaria">
                {aulas.map((aula) => (aula.nivel == "Primaria" ? <option value={aula.id_aula} key={aula.id_aula}>{`${aula.nombre}`}</option> : null))}
              </optgroup>
              <optgroup label="Secundaria">
                {aulas.map((aula) => (aula.nivel == "Secundaria" ? <option value={aula.id_aula} key={aula.id_aula}>{`${aula.nombre}`}</option> : null))}
              </optgroup>
            </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="correo" className="form-label">
              Email
            </label>
            <input type="email" className="form-control" name="correo" id="correo" />
          </div>
          <div className="col-md-3">
            <label htmlFor="dni" className="form-label">
              DNI
            </label>
            <input type="text" className="form-control" name="dni" id="dni" />
          </div>
          <div className="col-md-3">
            <label htmlFor="saldo" className="form-label">
              Saldo
            </label>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon1">
                S/
              </span>
              <input type="number" step={0.01} defaultValue="0.00" className="form-control" name="saldo" id="saldo" />
            </div>
          </div>
          <div className="col-md-3">
            <label htmlFor="matricula2023" className="form-label">
              Matricula 2023
            </label>
            <select id="matricula2023" name="matricula2023" className="form-select">
              <option value={1}>Sí</option>
              <option value={0}>No</option>
            </select>
          </div>
          <div className="col-md-12">
            <label htmlFor="direccion" className="form-label">
              Dirección
            </label>
            <textarea className="form-control" name="direccion" id="direccion" rows="2" />
          </div>
          <div className="col-md-12">
            <label htmlFor="observaciones" className="form-label">
              Observaciones
            </label>
            <textarea className="form-control" name="observaciones" id="observaciones" rows="5" />
          </div>
          <div className="col-md-12">
            <button type="submit" className="btn btn-success me-3">
              <i className="bi bi-plus-lg"></i> Registrar alumno
            </button>
            <button type="button" onClick={() => navigate(-1)} className="btn btn-secondary">
              <i className="bi bi-x-lg"></i> Cancelar
            </button>
          </div>
        </form>
      </>
    );
  }
}
