import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link, useParams, Form } from "react-router-dom";

export default function ModificarDatos() {
  let { id_alumno_apoderado } = useParams();
  const peticion = usePeticion();
  const navigate = useNavigate();

  const [estado, setEstado] = useState("cargando");
  const [relacion, setRelacion] = useState({});

  useEffect(function () {
    peticion("alumnos/parentesco/" + id_alumno_apoderado, {}, "Parentesco", "No se ha podido cargar la relación de parentesco entre el alumno y el apoderado", (json) => {
      setRelacion(json.relacion);
      setEstado("cargado");
    });
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    formData.append("id_alumno_apoderado", id_alumno_apoderado);
    peticion(
      "alumnos/editar_parentesco/",
      {
        method: "PUT",
        body: formData,
      },
      "Modificar parentesco",
      "No ha sido modificado el parentesco satisfatoriamente",
      () => {
        navigate("../perfil/" + relacion.id_alumno);
      }
    );
  }

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Editar parentesco</h1>
        <form onSubmit={handleSubmit} className="row g-3">
          <div className="col-md-12">
            <label htmlFor="parentesco" className="form-label">
              Parentesco
            </label>
            <input type="text" defaultValue={relacion.parentesco} className="form-control" id="parentesco" name="parentesco" />
          </div>
          <div className="col-md-12">
            <button type="submit" className="btn btn-warning me-3">
              <i className="bi bi-pencil"></i> Guardar cambio
            </button>
            <Link to={"../perfil/" + relacion.id_alumno} role="button" className="btn btn-secondary">
              <i className="bi bi-x-lg"></i> Cancelar
            </Link>
          </div>
        </form>
      </>
    );
  }
}
