// import logo from "./logo.svg";
// import "./App.css";
import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { API_URL } from "./conexion";
import { usePeticion } from "./herramientas";

function App() {
  const navigate = useNavigate();
  const [estado, setEstado] = useState("cargando");
  const [modulos, setModulos] = useState([]);
  const peticion = usePeticion();

  var bsOffcanvas = "";

  function cerrarOffCanvas() {
    bsOffcanvas.hide();
  }

  function handleCerrarSesion(e) {
    e.preventDefault();
    fetch(API_URL + "cerrar_sesion/", {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
      }),
    });
    localStorage.removeItem("token");
    navigate("/iniciar_sesion");
  }

  useEffect(() => {
    const bsOffcanvas = new window.bootstrap.Offcanvas("#offcanvasDarkNavbar");
  }, []);

  useEffect(() => {
    peticion("modulos_usuario/", {}, "Módulos de usuario", "No se han podido cargar los módulos del sistema al que el usuario tiene acceso", (json) => {
      setModulos(json.modulos);
      setEstado("cargado");
    });
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-xl navbar-dark bg-dark sticky-top">
        <div className="container-fluid">
          {/* <Link type="button" role="button" className="btn btn-outline-secondary me-3" to="../">
          <i className="bi bi-chevron-left"></i>
        </Link> */}
          <span className="navbar-brand mb-0 h1">IEP SPN</span>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="offcanvas offcanvas-end text-bg-dark" tabIndex={-1} id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
            <div className="offcanvas-header text-center">
              <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">
                IEP SPN
              </h5>
              <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li className="nav-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-label="Close">
                  <NavLink className="nav-link" to="/">
                    <i className="bi bi-house"></i> Inicio
                  </NavLink>
                </li>
                {modulos.map((modulo) => (
                  <li className="nav-item" key={modulo.id_modulo} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-label="Close">
                    <NavLink className="nav-link" to={modulo.link}>
                      <i className={modulo.bs_icono}></i> {modulo.modulo}
                    </NavLink>
                  </li>
                ))}
                <li className="nav-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-label="Close">
                  <a role="button" className="nav-link" onClick={handleCerrarSesion}>
                    <i className="bi bi-x-circle"></i> Cerrar sesión
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <main className="container p-3">
        <Outlet />
      </main>
    </>
  );
}

export default App;
