import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link, useParams } from "react-router-dom";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [datos, setDatos] = useState({});
  const [pagos, setPagos] = useState([]);

  const peticion = usePeticion();
  const navigate = useNavigate();
  let { id_alumno } = useParams();

  useEffect(function () {
    peticion("pagos/pagos/" + id_alumno, {}, "Pagos", "No se han podido cargar los pagos del alumno", (json) => {
      setDatos(json.datos);
      setPagos(json.pagos);
      setEstado("cargado");
    });
  }, []);

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Pagos</h1>
        <p className="lead mb-1">{datos.nombre_completo}</p>
        <p className="lead">{datos.nombre}</p>
        <div className="d-flex justify-content-between">
          <Link role="button" to={"../../alumnos/perfil/" + id_alumno} className="btn btn-primary mb-3">
          <i class="bi bi-person-circle"></i> Ir perfil alumno
          </Link>
          <Link role="button" to={"../emision_sunat/emitir_boleta/" + id_alumno} className="btn btn-primary mb-3">
          <i className="bi bi-receipt"></i> Emitir boleta SUNAT
          </Link>
        </div>
        <div className="table-responsive">
          <table className="table table-sm text-center table-bordered table-striped align-middle table-hover">
            <thead>
              <tr>
                <th scope="col">Boletas</th>
                <th scope="col">Concepto</th>
                <th scope="col">Precio</th>
                <th scope="col">Estado</th>
                <th scope="col">Monto pagado</th>
                <th scope="col">Obs.</th>
                <th scope="col"># cuotas</th>
              </tr>
            </thead>
            <tbody>
              {pagos.map((pago) => (
                <tr key={pago.id_pago_alumno}>
                  <td>
                    <Link role="button" to={"../cuotas/" + pago.id_pago_alumno} className="btn btn-primary btn-sm">
                      <i className="bi bi-files"></i>
                    </Link>
                  </td>
                  <td>{pago.concepto}</td>
                  <td>S/ {pago.precio}</td>
                  <td className={"table-"+pago.bs_color}>{pago.estado}</td>
                  <td>S/ {pago.monto}</td>
                  <td title={pago.observaciones} style={{cursor: "help"}}>Obs.</td>
                  <td>{pago.cantidad}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <h3>Observaciones del alumno</h3>
        <p>
          {datos.observaciones ? (
            datos.observaciones.split("\n").map((parrafo) => {
              return (
                <>
                  {parrafo}
                  <br />
                </>
              );
            })
          ) : (
            <i>No hay observaciones</i>
          )}
        </p>
      </>
    );
  }
}
