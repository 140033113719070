import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link, useParams, Form } from "react-router-dom";

export default function ModificarDatos() {
  const peticion = usePeticion();
  const navigate = useNavigate();

  const [estado, setEstado] = useState("cargado");

  function handleSubmit(event) {
    event.preventDefault();
    setEstado("cargando")
    let formData = new FormData(event.currentTarget);
    peticion(
      "cuenta/cambiar_contrasena/",
      {
        method: "PUT",
        body: formData,
      },
      "Cambiar contraseña",
      "No se pudo cambiar la contraseña de la cuenta",
      {
        titulo: "Cambiar contraseña",
        mensaje: "La contraseña de la cuenta ha sido actualizada con éxito",
        botones: [
          {
            nombre: "Ir a cuenta",
            color: "primary",
            direccion: "/cuenta"
          }
        ]
      }
    );
  }

  if (estado === "cargando") {
    return (
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Cambiar contraseña</h1>
        <form onSubmit={handleSubmit} class="row g-3">
          <div class="col-md-12">
            <label htmlFor="contrasena" class="form-label">
              Contraseña nueva
            </label>
            <input type="password" className="form-control" id="contrasena" name="contrasena" />
            <div className="form-text">Esta contraseña será la que utilice de aquí en adelante para acceder al sistema.</div>
          </div>
          <div class="col-md-12">
            <Link to={"../"} role="button" className="btn btn-secondary me-3 mr-3">
              <i class="bi bi-x-lg"></i> Cancelar
            </Link>
            <button type="submit" className="btn btn-success me-3">
              <i class="bi bi-check-lg"></i> Actualizar contraseña
            </button>
          </div>
        </form>
      </>
    );
  }
}
