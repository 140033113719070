import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link, useParams, Form } from "react-router-dom";

export default function ModificarDatos() {
  let { id_telefono_apoderado } = useParams();
  const peticion = usePeticion();
  const navigate = useNavigate();

  const [estado, setEstado] = useState("cargando");
  const [telefono, setTelefono] = useState({})

  useEffect(function () {
    peticion("apoderados/consultar_telefono/" + id_telefono_apoderado, {}, "Modificar teléfono de apoderado", "No ha podido ser cargado los detalles del teléfono del apoderado", (json) => {
      setTelefono(json.telefono);
      setEstado("cargado");
    });
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    setEstado("cargando")
    let formData = new FormData(event.currentTarget);
    formData.append("id_telefono_apoderado", id_telefono_apoderado)
    peticion(
      "apoderados/modificar_telefono/",
      {
        method: "PUT",
        body: formData,
      },
      "Modificar teléfono de apoderado",
      "No ha sido modificado el teléfono del apoderado satisfatoriamente",
      () => {
        navigate("../perfil/" + telefono.id_apoderado);
      }
    );
  }

  function handleEliminar() {
    if (window.confirm("Está seguro de aliminar el teléfono: " + telefono.telefono)) {
      let formData = new FormData();
      formData.append("id_telefono_apoderado", telefono.id_telefono_apoderado);
      peticion(
        "apoderados/eliminar_telefono/",
        {
          method: "DELETE",
          body: formData,
        },
        "Eliminar teléfono",
        "No ha sido eliminado el teléfono satisfatoriamente",
        () => {
          navigate("../perfil/" + telefono.id_apoderado)
        }
      );
    }
  }

  if (estado === "cargando") {
    return (
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Modificar teléfono de apoderado</h1>
        <form onSubmit={handleSubmit} class="row g-3">
          <div class="col-md-6">
            <label htmlFor="etiqueta" class="form-label">
              Etiqueta
            </label>
            <input type="text" defaultValue={telefono.etiqueta} className="form-control" id="etiqueta" name="etiqueta" />
          </div>
          <div class="col-md-6">
            <label htmlFor="telefono" class="form-label">
              Teléfono
            </label>
            <input type="tel" class="form-control" defaultValue={telefono.telefono} name="telefono" id="telefono" />
          </div>
          <div class="col-md-12">
            <button type="submit" href="perfil.html" class="btn btn-warning me-3">
              <i class="bi bi-pencil"></i> Guardar cambios
            </button>
            <button type="button" onClick={handleEliminar} className="btn btn-danger me-3">
              <i class="bi bi-trash3-fill"></i> Eliminar teléfono
            </button>
            <Link to={"../perfil/"+telefono.id_apoderado} role="button" class="btn btn-secondary">
              <i class="bi bi-x-lg"></i> Cancelar
            </Link>
          </div>
        </form>
      </>
    );
  }
}
