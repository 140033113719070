import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link } from "react-router-dom";
import { API_URL } from "../conexion";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [comprobantes, setComprobantes] = useState([]);
  const [comprobantesFiltrados, setComprobantesFiltrados] = useState([]);

  const [busqueda, setBusqueda] = useState("");

  const peticion = usePeticion();
  const navigate = useNavigate();

  useEffect(function () {
    peticion("comprobantes/", {}, "Comprobantes", "No ha podido ser cargada la lista general de comprobantes", (json) => {
      setComprobantes(json.comprobantes);
      setComprobantesFiltrados(json.comprobantes);
      setEstado("cargado");
    });
  }, []);

  function handleChangeBusqueda(event) {
    setBusqueda(event.target.value);
  }

  useEffect(
    function () {
      let asd = comprobantes.filter((fila) => {
        
        if (fila.numero.toString().includes(busqueda)) {
          return true;
        } else if (fila.nombre_completo != null) {
          if (fila.nombre_completo.toUpperCase().includes(busqueda.toUpperCase())) {
            return true;
          }
        } else if (fila.dni != null) {
          if (fila.dni.includes(busqueda)) {
            return true;
          }
        }
        return false;
      });
      setComprobantesFiltrados(asd);
    },
    [busqueda]
  );

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Comprobantes</h1>
        <form className="mb-3 row g-3">
          <div className="col-12">
            <input type="text" value={busqueda} onChange={handleChangeBusqueda} placeholder="Nombre, DNI o número de comprobante" className="form-control" />
          </div>
        </form>
        <div className="table-responsive">
          <table className="table table-sm text-center table-bordered table-striped align-middle table-hover">
            <thead>
              <tr>
                <th scope="col">Serie</th>
                <th scope="col">Número</th>
                <th scope="col">Alumno</th>
                <th scope="col">OSE</th>
                <th scope="col" colSpan={2}>PDF</th>
                <th scope="col">Anular...</th>
              </tr>
            </thead>
            <tbody>
              {comprobantesFiltrados.map((comprobante) => (
                <tr key={comprobante.id_comprobante_sunat}>
                  <td>{comprobante.anulada || comprobante.anulacion_comunicada ? <del>{comprobante.serie}</del> : comprobante.numero}</td>
                  <td>{comprobante.anulada || comprobante.anulacion_comunicada ? <del>{comprobante.numero}</del> : comprobante.numero}</td>
                  <td>{comprobante.anulada || comprobante.anulacion_comunicada ? <del>{comprobante.nombre_completo}</del> : comprobante.nombre_completo}</td>
                  <td>
                    <a className="btn btn-info btn-sm" target="_blank" href={comprobante.enlace}>
                      <i class="bi bi-file-break"></i>
                    </a>
                  </td>
                  <td>
                    <a
                      className="btn btn-danger btn-sm"
                      target="_blank"
                      href={API_URL + "comprobantes/pdf/" + comprobante.id_comprobante_sunat}
                    >
                      <i className="bi bi-file-earmark-pdf-fill"></i>
                    </a>
                  </td>
                  <td>
                    <a
                      className="btn btn-primary btn-sm"
                      download={comprobante.numero + " " + comprobante.nombre_completo}
                      href={API_URL + "comprobantes/pdf/" + comprobante.id_comprobante_sunat}
                    >
                      <i className="bi bi-download"></i>
                    </a>
                  </td>
                  <td><Link className="btn btn-sm btn-warning" to={"comunicar_anulacion/"+comprobante.id_comprobante_sunat} role="button"><i class="bi bi-x-square"></i></Link></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
