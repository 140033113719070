import React, { useState, useEffect } from "react";
import { usePeticion, useFecha } from "../herramientas";
import { useNavigate, Link, useParams } from "react-router-dom";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [fechas, setFechas] = useState([]);
  const [aula, setAula] = useState({});

  const peticion = usePeticion();
  const aFecha = useFecha();
  const { id_aula } = useParams();

  useEffect(function () {
    peticion("asistencia/fechas/"+id_aula, {}, "Asistencia", "No se han podido cargar las fechas de asistencia", (json) => {
      setAula(json.aula);
      setFechas(json.fechas);
      setEstado("cargado");
    });
  }, []);

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1><Link type="button" role="button" className="btn btn-outline-secondary me-3" to={"../"}>
          <i className="bi bi-chevron-left"></i>
        </Link>Asistencia</h1>
        <h2>{aula.nombre}</h2>
        <div className="table-responsive">
          <table className="table table-sm text-center table-bordered table-striped align-middle table-hover">
            <thead>
              <tr>
                <th scope="col">Tomar</th>
                <th scope="col">Día</th>
                <th scope="col">Fecha</th>
              </tr>
            </thead>
            <tbody>
              {fechas.map((fecha) => (
                <tr key={fecha.id_fecha_asistencia}>
                  <td>
                    <Link role="button" to={"../tomar/" + id_aula + "/" + fecha.fecha} className="btn btn-primary">
                      <i className="bi bi-clipboard-check"></i>
                    </Link>
                  </td>
                  <td>{fecha.dia}</td>
                  <td>{aFecha(fecha.fecha)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
