import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link, useParams, Form } from "react-router-dom";

export default function ModificarDatos() {
  let { id_apoderado } = useParams();
  const peticion = usePeticion();
  const navigate = useNavigate();

  const [estado, setEstado] = useState("cargado");

  function handleSubmit(event) {
    event.preventDefault();
    setEstado("cargando")
    let formData = new FormData(event.currentTarget);
    formData.append("id_apoderado", id_apoderado)
    peticion(
      "apoderados/agregar_telefono/",
      {
        method: "POST",
        body: formData,
      },
      "Agregar teléfono de apoderado",
      "No ha sido agregado el teléfono del apoderado satisfatoriamente",
      () => {
        navigate("../perfil/" + id_apoderado);
      }
    );
  }

  if (estado === "cargando") {
    return (
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Modificar teléfono de apoderado</h1>
        <form onSubmit={handleSubmit} class="row g-3">
          <div class="col-md-6">
            <label htmlFor="etiqueta" class="form-label">
              Etiqueta
            </label>
            <input type="text" defaultValue="celular" className="form-control" id="etiqueta" name="etiqueta" />
          </div>
          <div class="col-md-6">
            <label htmlFor="telefono" class="form-label">
              Teléfono
            </label>
            <input type="tel" class="form-control" name="telefono" id="telefono" />
          </div>
          <div class="col-md-12">
            <button type="submit" class="btn btn-success me-3">
              <i class="bi bi-plus-lg"></i> Agregar teléfono
            </button>
            <Link to={"../perfil/"+id_apoderado} role="button" class="btn btn-secondary">
              <i class="bi bi-x-lg"></i> Cancelar
            </Link>
          </div>
        </form>
      </>
    );
  }
}
