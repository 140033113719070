import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link } from "react-router-dom";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [alumnos, setAlumnos] = useState([]);
  const [alumnosFiltrados, setAlumnosFiltrados] = useState([]);
  const [aulas, setAulas] = useState([]);

  const [busqueda, setBusqueda] = useState("");
  const [filtroAula, setFiltroAula] = useState(0);
  const [filtroMatricula2023, setFiltroMatricula2023] = useState("-1");

  const peticion = usePeticion();
  const navigate = useNavigate();

  useEffect(function () {
    peticion("alumnos/lista/", {}, "Alumnos", "No ha podido ser cargada la lista general de alumnos", (json) => {
      setAlumnos(json.alumnos);
      setAlumnosFiltrados(json.alumnos);
      setAulas(json.aulas);
      setEstado("cargado");
    });
  }, []);

  function handleChangeBusqueda(event) {
    setBusqueda(event.target.value);
  }
  function handleChangeFiltroAula(event) {
    setFiltroAula(event.target.value);
  }

  useEffect(
    function () {
      let asd = alumnos.filter((fila) => {
        let a; let b; let c;
        if (fila.nombre_completo.toUpperCase().includes(busqueda.toUpperCase()) || fila.dni.includes(busqueda)) {
          a = true
        } else {
          a = false;
        }

        if (filtroAula != 0) {
          if (fila.id_aula == filtroAula) {
            b = true
          } else {
            b = false
          }
        } else {
          b = true;
        }

        if (filtroMatricula2023 != "-1") {
          if (fila.matricula2023 == filtroMatricula2023) {
            c = true
          } else {
            c = false
          }
        } else {
          c = true
        }

        return (a && b && c)
      });
      setAlumnosFiltrados(asd);
    },
    [busqueda, filtroAula, filtroMatricula2023]
  );

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Alumnos</h1>
        <p>
          Cantidad alumnos filtrados <span className="badge bg-secondary">{alumnosFiltrados.length}</span>
        </p>
        <Link role="button" to="registrar" className="btn btn-success me-3 mb-3">
        <i className="bi bi-plus-lg"></i> Registrar alumno
        </Link>
        <form className="mb-3 row g-3">
          <div className="col-12 col-md-6">
            <input type="text" value={busqueda} onChange={handleChangeBusqueda} placeholder="Nombre o DNI" className="form-control" />
          </div>
          <div className="col-12 col-md-3">
            <select className="form-select" value={filtroAula} onChange={handleChangeFiltroAula}>
              <option value={0}>
                Todas las aulas
              </option>
              <optgroup label="Inicial">
              {aulas.map((aula) => (
                aula.nivel == "Inicial" ? <option value={aula.id_aula} key={aula.id_aula}>{`${aula.nombre}`}</option> : null
              ))}
              </optgroup>
              <optgroup label="Primaria">
              {aulas.map((aula) => (
                aula.nivel == "Primaria" ? <option value={aula.id_aula} key={aula.id_aula}>{`${aula.nombre}`}</option> : null
              ))}
              </optgroup>
              <optgroup label="Secundaria">
              {aulas.map((aula) => (
                aula.nivel == "Secundaria" ? <option value={aula.id_aula} key={aula.id_aula}>{`${aula.nombre}`}</option> : null
              ))}
              </optgroup>
            </select>
          </div>
          <div className="col-12 col-md-3">
            <select className="form-select" value={filtroMatricula2023} onChange={(e)=>setFiltroMatricula2023(e.target.value)}>
              <option value={-1}>Matriculados y no matriculados</option>
              <option value={0}>No matriculados 2023</option>
              <option value={1}>Matriculados 2023</option>
            </select>
          </div>
        </form>
        <div className="table-responsive">
          <table className="table table-sm text-center table-bordered table-striped align-middle table-hover">
            <thead>
              <tr>
                <th scope="col">Perfil</th>
                <th scope="col">DNI</th>
                <th scope="col">Nombre completo</th>
                <th scope="col">Nivel</th>
                <th scope="col">Aula</th>
                <th scope="col">Mat. 2023</th>
              </tr>
            </thead>
            <tbody>
              {alumnosFiltrados.map((alumno) => (
                <tr key={alumno.id_alumno}>
                  <td>
                    <Link role="button" to={"perfil/" + alumno.id_alumno} className="btn btn-primary btn-sm">
                      <i className="bi bi-person-circle"></i>
                    </Link>
                  </td>
                  <td>{alumno.dni}</td>
                  <td>{alumno.nombre_completo}</td>
                  <td>{alumno.nivel}</td>
                  <td>{alumno.nombre}</td>
                  {alumno.matricula2023 ? <td className="table-success">Sí</td> : <td className="table-danger">No</td>}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
