import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, Link, useParams } from "react-router-dom";
import { API_URL } from "./../conexion";

export default function Index(props) {
  let { id_alumno } = useParams();

  const [datos, setDatos] = useState({});
  const [apoderados, setApoderados] = useState([]);
  const [estado, setEstado] = useState("cargando");
  const [documentos, setDocumentos] = useState([]);

  const peticion = usePeticion();

  function montar() {
    peticion("alumnos/perfil/" + id_alumno, {}, "Alumno", "No ha podido ser cargado el perfil del alumno", (json) => {
      setDatos(json.datos);
      setApoderados(json.apoderados);
      setDocumentos(json.documentos);
      setEstado("cargado");
    });
  }

  useEffect(montar, []);

  function handleDesasignar(id_alumno_apoderado) {
    var resultado = window.confirm("Está seguro que desea desasignar a este apoderado de este alumno");
    if (resultado) {
      setEstado("cargando");
      let formData = new FormData();
      formData.append("id_alumno", id_alumno);
      formData.append("id_alumno_apoderado", id_alumno_apoderado);
      peticion(
        "alumnos/desasignar_apoderado/",
        {
          method: "POST",
          body: formData,
        },
        "Desasignar apoderado",
        "No ha podido ser desasignado el apoderado del alumno satisfactoriamente.",
        () => {
          setEstado("cargado");
          montar();
        }
      );
    }
  }

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Perfil alumno</h1>
        <p className="lead">{datos.nombre_completo}</p>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <h2>Datos</h2>
            <Link role="button" className="btn btn-warning btn-sm" to={"../modificar_datos/" + id_alumno}>
              <i className="bi bi-pencil"></i> Modificar
            </Link>
            <table className="table align-middle text-center">
              <tbody>
                <tr>
                  <th scope="row">Aula</th>
                  <td>
                    {`${datos.nombre} ${datos.nivel}`}{" "}
                    <Link className="btn btn-primary btn-sm" to={"../../aulas/aula/" + datos.id_aula}>
                      <i className="bi bi-easel3"></i> Aula
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Matricula 2023</th>
                  {datos.matricula2023 ? <td className="table-success">Sí</td> : <td className="table-danger">No</td>}
                </tr>
                <tr>
                  <th scope="row">DNI</th>
                  <td>{datos.dni}</td>
                </tr>
                <tr>
                  <th scope="row">Email</th>
                  <td>
                    <a href={"mailto:" + datos.correo} target="_blank">
                      {datos.correo}
                    </a>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Saldo</th>
                  <td>S/ {datos.saldo}</td>
                </tr>
              </tbody>
            </table>
            <h3>Dirección</h3>
            <p>{datos.direccion}</p>
            <h2>Observaciones</h2>
            <p>
              {datos.observaciones ? (
                datos.observaciones.split("\n").map((parrafo) => {
                  return (
                    <>
                      {parrafo}
                      <br />
                    </>
                  );
                })
              ) : (
                <i>No hay observaciones</i>
              )}
            </p>
            <h2>Documentos</h2>
            <Link role="button" className="btn btn-success btn-sm mb-3" to={"../agregar_documento/" + id_alumno}>
              <i className="bi bi-plus-lg"></i> Agregar
            </Link>
            <div className="card">
              <ul className="list-group list-group-flush">
                {documentos.map((documento) => (
                  <li className="list-group-item" key={documento.nombre}>
                    <form target="_blank" action={API_URL + "alumnos/documentos/ver/"} method="post">
                      <input type="hidden" name="documento" value={documento.nombre}></input>
                      <input type="hidden" name="id_alumno" value={id_alumno}></input>
                      <div className="btn-group">
                        <button
                          type="submit"
                          target="_blank"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="bi bi-file-earmark"></i> Ver
                        </button>
                        <Link role="button" to={"../renombrar_documento/" + documento.nombre + "/" + id_alumno} className="btn btn-outline-warning btn-sm">
                          <i className="bi bi-pencil"></i>
                        </Link>
                      </div>
                      {" " + documento.nombre}
                    </form>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <h2>Apoderados</h2>
            <Link role="button" className="btn btn-success btn-sm mb-3" to={"../asignar_apoderado/" + id_alumno}>
              <i className="bi bi-plus-lg"></i> Asignar apoderado
            </Link>
            {apoderados.map((apoderado) => (
              <div className="card mb-3" key={apoderado.id_alumno_apoderado}>
                <div className="card-body">
                  <h5 className="card-title">{apoderado.nombre_completo + " "}</h5>
                  <h6 className="card-subtitle mb-2 text-muted">{apoderado.parentesco}</h6>
                  <div className="btn-group mb-2">
                    <Link to={"../../apoderados/perfil/" + apoderado.id_apoderado} role="button" className="btn btn-primary btn-sm">
                      <i className="bi bi-person-circle"></i> Perfil
                    </Link>
                    <Link to={`../editar_parentesco/${apoderado.id_alumno_apoderado}`} role="button" className="btn btn-outline-warning btn-sm">
                      <i className="bi bi-pencil"></i>
                    </Link>
                    <button onClick={() => handleDesasignar(apoderado.id_alumno_apoderado)} role="button" className="btn btn-outline-danger btn-sm">
                      <i className="bi bi-x-lg"></i>
                    </button>
                  </div>
                  {/* <p className="card-text mb-0">DNI: {apoderado.dni}</p>
                  <p className="card-text mb-0">
                    Email: <a href={"mailto:" + apoderado.correo}>{apoderado.correo}</a>
                  </p> */}
                </div>
              </div>
            ))}
            <h2>Pagos</h2>
            <Link className="btn btn-secondary" to={"../../pagos/pagos/"+id_alumno} role="button">Ver pagos del alumno</Link>
          </div>
        </div>
      </>
    );
  }
}
