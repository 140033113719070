import React, { useState, useEffect } from "react";
import { usePeticion, useFecha } from "../herramientas";
import { useNavigate, Link, useParams } from "react-router-dom";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [asistencia, setAsistencia] = useState([]);
  const [aula, setAula] = useState({});
  const [estadosAsistencia, setEstadosAsistencia] = useState([])
  const [alumnoSeleccionado, setAumnoSeleccionado] = useState({})

  const peticion = usePeticion();
  const aFecha = useFecha();
  const { id_aula, fecha } = useParams();

  useEffect(function () {
    peticion(
      "asistencia/asistencia_alumnos_fecha/" + id_aula + "/" + fecha,
      {},
      "Asistencia",
      "No se ha podido cargar la asistencia de los alumnos",
      (json) => {
        setAsistencia(json.asistencia);
        setAula(json.aula);
        setEstadosAsistencia(json.estados_asistencia);
        setEstado("cargado");
      }
    );
  }, []);

  function handleChecked(id_asistencia_alumno, id_estado_asistencia) {
    let asd = [...asistencia];
    asd.forEach((asis) => {
      if (asis.id_asistencia_alumno == id_asistencia_alumno) {
        asis.id_estado_asistencia = id_estado_asistencia;
      }
    });
    setAsistencia(asd);
  }

  function marcarTodo() {
    let asd = [...asistencia];
    let todo_no_registrado = true;
    asd.forEach((asis) => {
      todo_no_registrado = asis.id_estado_asistencia == 1 && todo_no_registrado;
    });

    if (todo_no_registrado) {
      asd.forEach((asis) => {
        asis.id_estado_asistencia = 2;
      });
      setAsistencia(asd);
    } else {
      if (window.confirm("Ya hay asistencias tomadas. ¿Está seguro de marcarlas con • ?")) {
        asd.forEach((asis) => {
          asis.id_estado_asistencia = 2;
        });
        setAsistencia(asd);
      }
    }
  }

  function handleSubmit(event) {
    setEstado("cargando");
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    formData.append("asistencia", JSON.stringify(asistencia));
    peticion(
      "asistencia/registrar/" + id_aula + "/" + fecha,
      {
        method: "POST",
        body: formData,
      },
      "Grabar asistencias",
      "Las asistencias no han podido ser grabadas satisfactoriamente",
      {
        titulo: "Grabar asistencias",
        mensaje: "Las asistencias han sido grabadas satisfactoriamente",
        botones: [
          {
            nombre: "Ir a fechas del aula",
            color: "primary",
            direccion: "../asistencia/fechas/" + id_aula,
          },
        ],
      }
    );
  }

  function editarObservaciones(id_alumno) {
    asistencia.forEach((asis)=>{
      if (asis.id_alumno == id_alumno) {
        setAumnoSeleccionado(asis)
      }
    })
  }

  function guardarObservaciones() {
    let asd = [...asistencia]
    asd.forEach(asis=>{
      if (asis.id_alumno == alumnoSeleccionado.id_alumno) {
        asis.observaciones = alumnoSeleccionado.observaciones
      }
    })
    setAsistencia(asd)
    setAumnoSeleccionado({})
  }

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>
          <Link type="button" role="button" className="btn btn-outline-secondary me-3" to={"../fechas/" + id_aula}>
            <i className="bi bi-chevron-left"></i>
          </Link>
          Asistencia
        </h1>
        <h3>{aula.nombre}</h3>
        <p className="lead">{aFecha(fecha)}</p>
        <p>
          {estadosAsistencia.map((asis) => (
            <React.Fragment key={asis.id_estado_asistencia}>
              <span  className={"badge text-bg-" + asis.bs_color}> {asis.simbolo}</span> {asis.nombre}
              <br />
            </React.Fragment>
          ))}
        </p>
        <div className="d-flex justify-content-end">
          <button type="button" className="btn btn-success mb-3" onClick={marcarTodo}>
            Marcar todo •
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="table-responsive">
            <table className="table text-center table-bordered table-striped align-middle table-hover">
              <thead>
                <tr>
                  <th scope="col">Alumno</th>
                  <th scope="col">Asistencia</th>
                  <th scope="col">Obs.</th>
                </tr>
              </thead>
              <tbody>
                {asistencia.map((asis) => (
                  <tr key={asis.id_asistencia_alumno}>
                    <td>{asis.nombre_completo}</td>
                    <td>
                      <div className="btn-group" role="group">
                        {estadosAsistencia.map((est) => (
                          <React.Fragment key={asis.id_asistencia_alumno + "-" + est.id_estado_asistencia}>
                            <input
                              
                              type="radio"
                              className="btn-check"
                              name={"asistencia-" + asis.id_asistencia_alumno}
                              id={"radio" + asis.id_asistencia_alumno + "-" + est.id_estado_asistencia}
                              checked={asis.id_estado_asistencia == est.id_estado_asistencia}
                              onChange={(e) => handleChecked(asis.id_asistencia_alumno, est.id_estado_asistencia)}
                            />
                            <label className={"btn btn-outline-" + est.bs_color} htmlFor={"radio" + asis.id_asistencia_alumno + "-" + est.id_estado_asistencia}>
                              {est.simbolo}
                            </label>
                          </React.Fragment>
                        ))}
                        
                      </div>
                    </td>
                    <td>
                      <input type="hidden" value={asis.observaciones == null ? "" : asis.observaciones} name={"observaciones-"+ asis.id_asistencia_alumno} />
                      <button
                        type="button"
                        className={"btn " + (asis.observaciones == "" || asis.observaciones == null ? "btn-info" : "btn-warning")}
                        onClick={()=>editarObservaciones(asis.id_alumno)}
                        data-bs-toggle="modal" data-bs-target="#modalObservaciones"
                      >
                        Obs
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              <i className="bi bi-save"></i> Grabar asistencias
            </button>
          </div>
        </form>
        <div className="modal fade" id="modalObservaciones" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">{alumnoSeleccionado.nombre_completo}</h1>
            </div>
            <div className="modal-body">
              <form>
                <div>
                  <label htmlFor="observaciones" className="col-form-label pt-0">Observaciones</label>
                  <textarea className="form-control" rows="5" id="observaciones" value={alumnoSeleccionado.observaciones == null ? "" : alumnoSeleccionado.observaciones} onChange={(e)=>setAumnoSeleccionado({...alumnoSeleccionado, observaciones: e.target.value})} />
                  <div id="emailHelp" className="form-text">Deberá de grabar las asistencias para que las observaciones sean registradas.</div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>setAumnoSeleccionado({})}>Cancelar</button>
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={guardarObservaciones} >Aceptar</button>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}
