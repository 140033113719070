import React, { useState, useEffect } from "react";
import { usePeticion } from "../herramientas";
import { useNavigate, useParams, Link } from "react-router-dom";
import { API_URL } from "../conexion";
export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [alumnos, setAlumnos] = useState([]);
  const [aula, setAula] = useState([]);
  const [tutor, setTutor] = useState({});
  const [auxiliar, setAuxiliar] = useState({});

  const { id_aula } = useParams();

  const peticion = usePeticion();
  const navigate = useNavigate();

  useEffect(function () {
    peticion("aulas/aula/" + id_aula, {}, "Aula", "No ha podido ser cargada la información del aula.", (json) => {
      setAlumnos(json.alumnos);
      setAula(json.aula);
      setTutor(json.tutor);
      setAuxiliar(json.auxiliar);
      setEstado("cargado");
    });
  }, []);

  if (estado === "cargando") {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1>Aula</h1>
        <p className="lead">
          {aula.nombre} {aula.nivel}
        </p>
        <Link role="button" className="btn btn-warning mb-3" to={"../modificar/" + aula.id_aula}>
          <i className="bi bi-pencil"></i> Modificar aula
        </Link>
        <h5>Tutor</h5>
        <p>{tutor?.nombre}</p>
        <h5>Auxiliar</h5>
        <p>{auxiliar?.nombre}</p>
        <p>
          Cantidad de alumnos <span className="badge bg-secondary">{alumnos.length}</span>
        </p>
        <p>
          Cantidad de alumnos matriculados{" "}
          <span className="badge bg-secondary">
            {
              alumnos.filter((alumno) => {
                return alumno.matricula2023;
              }).length
            }
          </span>
        </p>
        <div className="d-flex justify-content-between">
          <Link role="button" to={"../../alumnos/registrar/" + aula.id_aula} className="btn btn-success me-3 mb-3">
            <i className="bi bi-plus-lg"></i> Registrar alumno
          </Link>
          <a target="_blank" href={API_URL + "aulas/exportar_excel/" + aula.id_aula} role="button" className="btn btn-success mb-3">
            <i className="bi bi-file-earmark-spreadsheet"></i> Exportar a Excel
          </a>
        </div>
        <div className="table-responsive">
          <table className="table table-sm text-center table-bordered table-striped align-middle table-hover">
            <thead>
              <tr>
                <th scope="col">Perfil</th>
                <th scope="col">DNI</th>
                <th scope="col">Nombre completo</th>
                <th scope="col">Email</th>
                <th scope="col">Mat. 2023</th>
              </tr>
            </thead>
            <tbody>
              {alumnos.map((alumno) => (
                <tr key={alumno.id_alumno}>
                  <td>
                    <Link role="button" to={"../../alumnos/perfil/" + alumno.id_alumno} className="btn btn-primary btn-sm">
                      <i className="bi bi-person-circle"></i>
                    </Link>
                  </td>
                  <td>{alumno.dni}</td>
                  <td>{alumno.nombre_completo}</td>
                  <td>{alumno.correo}</td>
                  {alumno.matricula2023 ? <td class="table-success">Sí</td> : <td class="table-danger">No</td>}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <h2>Observaciones</h2>
        <p>
          {aula.observaciones !== null ? (
            aula.observaciones.split("\n").map((parrafo) => {
              return (
                <>
                  {parrafo}
                  <br />
                </>
              );
            })
          ) : (
            <i>No hay observaciones</i>
          )}
        </p>
      </>
    );
  }
}
