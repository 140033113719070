import { useNavigate, useLocation, Link } from "react-router-dom";

export function Exito(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <h1>
        {state.titulo}
      </h1>
      <div className="alert alert-success">
        {state.mensaje}
      </div>
      {state.botones.map((boton, index) => (
        <Link key={index} to={boton.direccion} role="button" className={`btn btn-${boton.color} mb-3 me-3`}>
          {boton.nombre}
        </Link>
      ))}
    </>
  );
}

export function Error(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <h1>
        {state.titulo}
      </h1>
      <div className="alert alert-danger">
        <h4 className="alert-heading">{state.mensaje}</h4>
        Vuelve a intentar realizar la operación o de lo contrario por favor comunícate con análisis de sistemas y envía una captura de pantalla
        <br />
        <br />
        {state.error}
      </div>
      <a target="_blank" href="https://wa.me/51998884006" className="btn btn-success mb-3 me-3" role="button">
        <i className="bi bi-whatsapp"></i> WhatsApp
      </a>
      <button type="button" onClick={()=>navigate(-2)} className="btn btn-secondary mb-3">
        <i className="bi bi-x-lg"></i> Regresar
      </button>
    </>
  );
}

export function NoAutorizado(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <h1>
        {state.titulo}
      </h1>
      <div className="alert alert-warning">
        <h4 className="alert-heading">Usted no está autorizado para realizar esta operación</h4>
      </div>
      <button onClick={() => navigate("/")} className="btn btn-warning mb-3">
        Ir a Inicio
      </button>
    </>
  );
}